<div mat-dialog-container style = "padding: 0px !important;">
  <mat-toolbar *ngIf="data.title!==''" [class]="class"
               style="width: auto !important; margin: -24px !important; margin-bottom: 20px !important;">
    <mat-icon [svgIcon]="icon"></mat-icon>
    <mat-card-header>{{data.title}}</mat-card-header>
  </mat-toolbar>
  <div class="message">
    <p>
      {{data.message}}
    </p>
  </div>
  <div class="info" *ngIf="data.info!==''">
    <p>{{data.info}}</p>
  </div>
  <div style="padding: 5px; margin-top: 10px; float:right">
    <div *ngIf="data.button==0">
      <button class="mat-raised-button success-color-scheme" (click)="onOk()" tabindex="-1">OK</button>
    </div>
    <div *ngIf="data.button==1">
      <button class="mat-raised-button success-color-scheme" style="margin-right: 10px;" (click)="onOk()" tabindex="-1">OK</button>
      <button class="mat-raised-button error-color-scheme" (click)="onCancel()" tabindex="-1">CANCEL</button>
    </div>
    <div *ngIf="data.button==2">
      <button class="mat-raised-button success-color-scheme" style="margin-right: 10px;" (click)="onYes()" tabindex="-1">YES</button>
      <button class="mat-raised-button error-color-scheme" (click)="onNo()" tabindex="-1">NO</button>
    </div>
    <div *ngIf="data.button==3">
      <button class="mat-raised-button success-color-scheme" style="margin-right: 10px;" (click)="onAccept()" tabindex="-1">ACCEPT</button>
      <button class="mat-raised-button error-color-scheme" (click)="onReject()" tabindex="-1">REJECT</button>
    </div>
    <div *ngIf="data.button==4">
      <button class="mat-raised-button success-color-scheme" style="margin-right: 10px;" (click)="onYes()" tabindex="-1">YES</button>
      <button class="mat-raised-button warning-color-scheme" style="margin-right: 10px;" (click)="onNo()" tabindex="-1">NO</button>
      <button class="mat-raised-button error-color-scheme" (click)="onCancel()" tabindex="-1">CANCEL</button>
    </div>
  </div>

</div>
