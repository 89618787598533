import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ConfigService } from 'core-services';
import { ICurrentUser } from '@app/auth/auth.model';
import { CurrentUserService } from '@app/auth/current-user.service';
import * as utils from '@app/helpers/general-helper';
import { API } from '@app/core/api';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private configService: ConfigService, private currentUserService: CurrentUserService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if user is logged in and request is to the api url
        const apiUrl = API.apiUrl;//this.configService.get('api', 'baseURL');
        const currUser: ICurrentUser = this.currentUserService.retrieve();
        const isLoggedIn = (!utils.isNullorUndefined(currUser)) && (!utils.isNullorUndefined(currUser.accessToken));
        const isApiUrl = request.url.startsWith(apiUrl);
        if (isLoggedIn && isApiUrl) {
            request = request.clone({
                setHeaders: { Authorization: `Bearer ${currUser.accessToken}` }
            });
            // console.log('jwtInterceptor added header: ' + `Bearer ${currUser.accessToken}`) // Debug
        }
        return next.handle(request);
    }
}