import { formatDate } from '@angular/common';

// return True if object passed is null or undefined
export function isNullorUndefined(obj?: any): boolean {
    return obj === null || obj === undefined;
}

// return string with first word first letter capital and lower the other
export function titleCase(str: string) {
    return str[0].toUpperCase() + str.slice(1).toLowerCase();
}

export function comparatorByDateDesc(a: string, b: string) {
    return new Date(b).getTime() - new Date(a).getTime();
}

//sort by date desc
export function sortByDateDesc(array) {
    return array.sort((a, b) => {
        return getTime(b.datetime) - getTime(a.datetime);
    });
}

//Object deep clone
export function deepClone(object: Object) {
    return JSON.parse(JSON.stringify(object));
}

//#region ###############################  Dates and Times  ##################################

/** Function to format a date and time as a string.
 * @param dateAndTime - The date and time to format.
 * @return the date and time in a format of: dd/MM/yy HH:mm*/
export function formatDateAndTime(dateAndTime: string) {
    let dateFormat = 'dd/MM/yy HH:mm';
    let time = formatDate(dateAndTime, dateFormat, 'en-IL');
    return time;
}

/**
 * get now DateTime string
 * @returns the now DateTime
 */
export function getNow(format: string = 'YYYY-MM-dd HH:mm', colture: string = 'en-IL'): string {
    return formatDate(new Date(), format, colture);
}

// Converts UTC date string to Locale date string
export function convertToLocaleDate(dateString: string, dateFormat: string = null): string {
    let d = new Date(dateString);
    if (dateString.endsWith('Z')) {
        let dotIndex = dateString.indexOf('.');
        let newDateString = dateString.substring(0, dotIndex);
        d = new Date(newDateString);
    }
    d = convertUTCDateToLocalDate(d);
    return dateFormat ? formatDate(d, dateFormat, 'en-IL') : d.toLocaleString();
}

/**
 * concerts utc to local datetime
 * @param datetime the datetime in utc
 * @returns the datetime in local date
 */
export function convertUTCDateToLocalDate(datetime: Date): Date {
    datetime.setMinutes(datetime.getMinutes() + Math.abs(new Date(datetime).getTimezoneOffset()));
    return datetime;
}

export function getTime(date?: Date) {
    return date != null ? date.getTime() : 0;
}

//shows formatted date time in view
export function setLocalDateTime(datetime: Date, dateFormat = 'dd/MM/yy HH:mm:ss', local = 'en-IL') {
    if (datetime instanceof Date) {
        return formatDate(datetime, dateFormat, local);
    } else return '';
}

/**
 * Parse a date string in format dd/MM/yy HH:mm to Date object
 * @param dateString the date string in format dd/MM/yy HH:mm
 * @returns the date object
 */
export function parseDateString(dateString: string): Date {
    let dateParts = dateString.split(' ')[0].split('/').map(part => parseInt(part));
    let timeParts = dateString.split(' ')[1].split(':').map(part => parseInt(part));
    return new Date(dateParts[2], dateParts[1] - 1, dateParts[0], timeParts[0], timeParts[1]);
}

//#endregion ############################  Dates and Times  ##################################

//#region ###############################  Numbers  ##################################

export function isFloat(n): boolean {
    return Number(n) == n && n % 1 !== 0;
}

/**
 * Returns a string representing a number in fixed-point notation.
 * @param value the value
 * @param fractionDigits Number of digits after the decimal point. Must be in the range 0 - 20, inclusive.
 * @returns
 */
export function toFixedFloat(value, fractionDigits = 2): string {
    return parseFloat(value).toFixed(fractionDigits);
}

// return random number
export function getRandomInt(max = 10, min = 0) {
    return Math.floor(Math.random() * (max - min) + min); // 0 - 9
}

//#endregion ############################  Numbers  ##################################
