import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PushNotificationSubscriptionService {
    private subject = new Subject<any>();

    sendSubscriptionEvent() {
        this.subject.next(true);
    }

    getSubscriptionEvent(): Observable<any> {
        return this.subject.asObservable();
    }
}
