import { CalendarItem } from "./CalendarItem";

export interface IFeature {
  Code: string;
  Description: string;
  IsActive: boolean;
  IsDirty: boolean;
  VirtualDisconnect: number;
  HolidayCalendarItem: CalendarItem;
}

export class Feature implements IFeature {
  Code: string = '';
  Description: string = '';
  IsActive: boolean = false;
  IsDirty: boolean  = false;
  VirtualDisconnect: number;
  HolidayCalendarItem: CalendarItem;
  constructor(jsonObj: IFeature) {
    this.jsonToClassAdapter(jsonObj);
  }

  private jsonToClassAdapter(jsonObj: any) {
    Object.keys(this).forEach((prop) => {
      this[prop] = jsonObj[prop];
    });
  }
}
