// Adapted from https://github.com/trashvin/messagebox-like-angular-alertbox

export enum MessageBoxType {
  Default = 0,
  Notification = 1,
  Success = 2,
  Warning = 3,
  Error = 4
}

export enum MessageBoxButton {
  Ok = 0,
  OkCancel = 1,
  YesNo = 2,
  AcceptReject = 3,
  YesNoCancel = 4
}

// Toast Notification Properties
export interface IMessageBoxData {
  type?: MessageBoxType;
  title?: string;
  message: string;
  info?: string;
  width?: string; // Default will become 25%
  button?: MessageBoxButton; // Default will become 'Ok'
  overlayClick?: boolean; // Default will become 'true'
}
