export enum NotificationBarType {
  Default = 0,
  Info = 1,
  Success = 2,
  Warning = 3,
  Error = 4
}

// Notification Bar Properties
export interface INotificationBarData {
  type?: NotificationBarType;
  message: string;
  action: string;
  styleClass: string;
  duration: number;
}

// Default values
export const NotificationBarDefaultValues = {
  // General
  type: NotificationBarType.Default,
  infoDuration: 2000,
  actionDuration: 5000,
  styleClass: 'default-color-scheme',
};
