import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SubscriberContact } from '@app/models';
import { GenericHttpClientService } from 'core-services';
import { API } from '@app/core/api';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

const defaultUri = API.subscriberContactsUrl;

@Injectable({
    providedIn: 'root',
})
export class SubscriberContactsService extends GenericHttpClientService<SubscriberContact, string> {
    /**
     * read contact
     * @param id the contact identifier
     * @returns Observable of SubscriberContact
     */
    readOne(id: string): Observable<SubscriberContact> {
        return super.readOne(defaultUri + id);
    }

    /**
     * read subscriber's contacts by api
     * @param subscriberId the subscriber identifier
     * @returns Observable of SubscriberContact[]
     */
    readList(subscriberId: string): Observable<SubscriberContact[]> {
        return super.readList(defaultUri + subscriberId).pipe(map((data: SubscriberContact[]) => adaptList(data)));
    }
}

const adapt = (item: any): SubscriberContact => new SubscriberContact(item);

const adaptList = (data: SubscriberContact[]): SubscriberContact[] => data.map(adapt);
