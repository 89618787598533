import { NgxLoggerLevel } from 'core-services';
import CryptoJS from 'crypto-js';

export const environment = {
  envName: 'prod',
  envFullName: 'IoT-CT-Prod',
  production: true,
  logLevel: NgxLoggerLevel.OFF,
  serverLogLevel: NgxLoggerLevel.ERROR,
  encKey: "9sD^7X6!k@3#G8%vF2&yP5qW+eZr4sVt",
};

// In order to run production use: ng serve --configuration=production
