import { Injectable } from '@angular/core';
import { ICurrentUser } from './auth.model';
import { PubSubService } from 'core-services';

const _topic = 'current-user';

@Injectable({
  providedIn: 'root'
})
export class CurrentUserService extends PubSubService {

  constructor() { super(); }

  // Publish Current User (after login / logout / appInit)
  public publish(value: ICurrentUser) {
    if (ICurrentUserTypeGuard(value)) { super.publish(_topic, value); }
  }

  // subscribe to message
  public subscribe() {
    return super.subscribe(_topic);
  }

  // Consumers can retrieve current state without waiting
  public retrieve() {
    if (this._topics.has(_topic)) {
      return this._topics.get(_topic).getValue();
    }
  }

}

// Type Guard functions
export function ICurrentUserTypeGuard(inputObj: any): inputObj is ICurrentUser | null {
  if (inputObj === null ) { return true; }
  return 'userId' in inputObj && typeof inputObj['userId'] === 'string' &&
         'loginName' in inputObj && typeof inputObj['loginName'] === 'string' &&
         'fullName' in inputObj && typeof inputObj['fullName'] === 'string';
}
