import { CalendarItem } from "./CalendarItem";
import { IFeatureStateBase } from "./feature-state-base";

export interface IFeatureState extends IFeatureStateBase {
    DeviceSerialNumber: string;
    IsDeviceConnected: boolean;
    Zone: any;
    CurrentFeatureState: string;
    LastActivity: string;
    Id: string;
    Value: string;
    TempAverage: number;
    TempValue: number;
    AlertReasons: string[];
    CreatedOn: string;
    ModifiedOn: string;
    IsActiveFeature: string;
    VirtualDisconnect: number;
    HolidayCalendarItem: CalendarItem;
}

export class FeatureState implements IFeatureState {
    SubscriberId: string;
    DeviceSerialNumber: string;
    IsDeviceConnected: boolean;
    Feature: string;
    Zone: any;
    CurrentFeatureState: string;
    LastActivity: string;
    Id: string;
    IsAlert: boolean;
    Reason: string; //TODO: AlertReasons and Reason?
    Value: string;
    // additional data
    TempAverage: number;
    TempValue: number;
    AlertReasons: string[];
    CreatedOn: string;
    ModifiedOn: string;
    IsActiveFeature: string;
    VirtualDisconnect: number;
    HolidayCalendarItem: CalendarItem;
    AlertColor: string;
    AlertPriority: number;
    constructor(jsonObject: IFeatureState) {
        this.jsonToClassAdapter(jsonObject);
    }

    private jsonToClassAdapter(jsonObject: IFeatureState) {
        Object.assign(this, jsonObject);
    }
}
