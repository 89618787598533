// TODO: is in use?

import { Component, Inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate } from '@angular/service-worker';
import { NotificationBarService } from 'notification-bar';

// @Injectable()
@Injectable({ providedIn: 'root' })
export class UpdateService {
    constructor(
        private updates: SwUpdate,
        private snackbar: MatSnackBar,
        public dialog: MatDialog,
        private readonly notificationBarService: NotificationBarService
    ) {
        console.log('%c UpdateService', 'background: red;');

        this.updates.available.subscribe((event) => {
            console.log(event);
            this.showAppUpdateAlert();
        });
    }

    showAppUpdateAlert() {
        const header = 'New App Update is Available';
        const message = 'Choose Ok to update';
        const action = this.doAppUpdate;
        const caller = this;
        // presentAlert(header, message, action, caller);
        this.notificationBarService.notifySuccess('New App Update is Available');
        console.log('%c App Update available', 'background: red;');
        this.openDialog();
    }

    doAppUpdate() {
        this.updates.activateUpdate().then(() => document.location.reload());
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(AvailableUpdateDialog, {
            width: '250px',
            backdropClass: 'backdrop-dialog',
        });

        dialogRef.afterClosed().subscribe((result) => {
            console.log('The dialog was closed');
            if (result == true) {
                this.doAppUpdate();
            }
        });
    }
}

@Component({
    selector: 'updateDialog',
    templateUrl: 'updateDialog.html',
})
export class AvailableUpdateDialog {
    constructor(public dialogRef: MatDialogRef<AvailableUpdateDialog>) {}

    onNoClick(): void {
        this.dialogRef.close();
    }
}
