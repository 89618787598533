import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export abstract class PubSubService {

  protected _topics = new Map<any, BehaviorSubject<any>>(); // https://codecraft.tv/courses/angular/es6-typescript/mapset/

  // Publishers publishing data
  protected publish(topic: any, value: any) {
    if (this._topics.has(topic)) {
      this._topics.get(topic).next(value);
    } else {
      this._topics.set(topic, new BehaviorSubject(value));
    }
  }

  // Consumers will subscribe to this, to get the latest updates
  protected subscribe(topic: any) {
    if (this._topics.has(topic)) {
      return this._topics.get(topic).asObservable();
    } else {
      this._topics.set(topic, new BehaviorSubject(undefined)); // Subscribing to a topic never published yet results in creation of a new topic as undefined
      return this._topics.get(topic).asObservable();
    }
  }

  // Consumers can retrieve current state without waiting
  protected retrieve(topic: any) {
    if (this._topics.has(topic)) {
      return this._topics.get(topic).getValue();
    }
  }

}
