import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '@app/core/api';
import { IPushSubscription } from '@app/models';
import { GenericHttpClientService } from 'core-services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class PushNotificationService extends GenericHttpClientService<any, string> {
    constructor(private httpClient: HttpClient) {
        super(httpClient);
    }

    create(sub): Observable<IPushSubscription> {
        let url = API.pushNotification;
        return this.httpClient.post<IPushSubscription>(url, sub);
    }

    deleteSubscription(subscription: IPushSubscription) {
        let deleteUrl = API.pushNotification + '/delete';
        return this.httpClient.put(deleteUrl, subscription);
    }

    /**
     * send a subscription object update request to the server
     * @param subscription
     * @returns the subsciption object
     */
    updateSubscription(subscription: IPushSubscription): Observable<Object> {
        let updateUrl = API.pushNotification + '/update';
        return this.httpClient.put(updateUrl, subscription);
    }

    /**
     * send a subscription mute/unmye all request to the server
     * @param mute
     * @returns the subscription objects
     */
    muteUnmuteSubscriptions(mute: boolean): Observable<Object> {
        let muteUnmuteUrl = API.pushNotification + '/muteUnmute';
        return this.httpClient.put(muteUnmuteUrl, mute);
    }
    /**
     * send a subscription mute/unmye all request to the server
     * @param mute
     * @returns the subscription objects
     */
    getUserSubscriptions(): Observable<Object> {
        let getUserSubscriptionsUrl = API.pushNotification + '/user-subscriptions';
        return this.httpClient.get(getUserSubscriptionsUrl);
    }
}
