import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '@app/core/api';
import { Subscriber, Device, ISubscriber } from '@app/models';
import { GenericHttpClientService } from 'core-services';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class SubscribersApiService extends GenericHttpClientService<Subscriber, string> {
    /**
     * gets User's Subscribers by api
     * @param id user id
     * @returns Observable of Subscriber[]
     */
    getUserSubscribers(id: string): Observable<Subscriber[]> {
        let url = API.subscriberApiUrl + '/user/' + id;
        return this.httpClient.get<Subscriber[]>(url).pipe(map((data: ISubscriber[]) => this.adaptList(data)));
    }

    /**
     * gets subscriber by api
     * @param id the subscriber id
     * @returns Observable of Subscriber
     */
    getSubscriber(id: string): Observable<Subscriber> {
        let url = API.subscriberApiUrl + '/' + id;
        return this.httpClient.get<Subscriber>(url);
    }

    /**
     * creates the subsriber by api
     * @param uri subscriber Api Url
     * @param item Subscriber
     * @returns Observable
     */
    create(uri: string, item: Subscriber): Observable<Subscriber> {
        return super.create(API.subscriberApiUrl, item);
    }

    /**
     * updates the subsriber by api
     * @param uri subscriber Api Url
     * @param item Subscriber
     * @returns Observable
     */
    update(uri: string, item: Subscriber): Observable<Subscriber> {
        return super.update(API.subscriberApiUrl, item);
    }

    /**
     * delets the subsriber by api
     * @param uri subscriber id
     * @returns Observable
     */
    deleteSubscriber(id: string): Observable<Subscriber> {
        return super.delete(`${API.subscriberApiUrl}/${id}`);
    }

    constructor(private httpClient: HttpClient) {
        super(httpClient);
    }

    private adapt(item: ISubscriber): Subscriber {
        return new Subscriber(item);
    }

    private adaptList(items: ISubscriber[]): Subscriber[] {
        return items.map(this.adapt);
    }
}
