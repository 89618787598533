import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageBoxComponent } from './message-box.component';
import { MessageBoxService } from './message-box.service';

import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    MatToolbarModule,
    MatDialogModule,
    MatIconModule
  ],
  declarations: [MessageBoxComponent],
  exports: [MatDialogModule, MatCardModule, MatToolbarModule, MessageBoxComponent, MatIconModule],
  providers: [MessageBoxService]
})
export class MessageBoxModule { }



