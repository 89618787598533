import { Injectable } from '@angular/core';
import { API } from '@app/core/api';
import { FeatureState, Alert, HandledAlert, IFeatureState, IAlert, IHandledAlert } from '@app/models';
import { GenericHttpClientService } from 'core-services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class AlertService extends GenericHttpClientService<IAlert | IFeatureState | IHandledAlert | string, string> {
    /**
     * read list of alerts from specified url
     * @param request the uri with params
     * @returns Observable of IAlert[]
     */
    readList(request: string): Observable<IAlert[]> {
        return super.readList(request)
                    .pipe(map((data: IAlert[]) => adaptAlertList(data)));
    }

    /**
     * gets Active Alerts list
     * @returns Observable of FeatureState[]
     */
    getSubscriberFeatureStatesList(accountId?: string): Observable<FeatureState[]> {
        return super.readList(API.alertsApiUrl + accountId)
                    .pipe(map((data: IFeatureState[]) => adaptSubscriberFeatureStatesList(data)));
    }

    /**
     * read list of handled alerts from specified url
     * @param request
     * @returns Observable of IHandledAlert[]
     */
    getHandledAlertsList(request: string): Observable<IHandledAlert[]> {
        return super.readList(request)
                    .pipe(map((data: IHandledAlert[]) => adaptHandledAlertList(data)));
    }
    /**
     * close alert
     * @returns Observable of FeatureState[]
     */
    closeAlert(alertID: string): Observable<any> {
        return super.update(API.closingAlertUrl, alertID);
    }
    /**
     * close alert
     * @returns Observable of FeatureState[]
     */
    closeAlerts(subscriberId: string): Observable<any> {
        return super.update(API.closingAlertsUrl, subscriberId);
    }
}

const adaptAlert = (data: IAlert): Alert => new Alert(data);
const adaptAlertList = (data: IAlert[]): Alert[] => data.map(adaptAlert);

const adaptHandledAlert = (data: IHandledAlert): HandledAlert => new HandledAlert(data);
const adaptHandledAlertList = (data: IHandledAlert[]): HandledAlert[] => data.map(adaptHandledAlert);

const adaptSubscriberFeatureState = (data: IFeatureState): FeatureState => new FeatureState(data);
const adaptSubscriberFeatureStatesList = (data: IFeatureState[]): FeatureState[] => data.map(adaptSubscriberFeatureState);
