export interface IUserProfile {
  AccountId: string;
  AccountName?: string;
  ProfileName?: string;
  Id: string;
  UserProfile: string;
  UserId?: string;
}

export class UserProfile implements IUserProfile {
  AccountId: string = '';
  AccountName?: string = '';
  ProfileName?: string = '';
  Id: string = '';
  UserProfile: string = '';
  UserId?: string = '';
  PreferredLanguage?: string = '';

  constructor(jsonObj?: IUserProfile) {
    jsonObj && this.jsonToClassAdapter(jsonObj);
  }

  private jsonToClassAdapter(jsonObj: IUserProfile) {
    Object.keys(this).forEach((prop) => (this[prop] = jsonObj[prop]));
  }
}
