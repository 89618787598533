import { Component, OnInit, Inject, NgZone } from '@angular/core';
import { IMessageBoxData, MessageBoxType } from './message-box.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.scss']
})
export class MessageBoxComponent implements OnInit {

  icon = 'bell-alert';
  class = 'mat-elevation-z2 ';

  ngOnInit() {
  }

  constructor(
    public dialogRef: MatDialogRef<MessageBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IMessageBoxData) {
      switch(data.type) { 
        case MessageBoxType.Default: { 
          this.icon = 'note';
          this.class = this.class + 'default-color-scheme';
          break; 
        } 
        case MessageBoxType.Notification: { 
          this.icon = 'comment';
          this.class = this.class + 'info-color-scheme';
          break; 
        } 
        case MessageBoxType.Success: { 
          this.icon = 'hand-okay';
          this.class = this.class + 'success-color-scheme';
          break; 
        } 
        case MessageBoxType.Warning: { 
          this.icon = 'comment-alert';
          this.class = this.class + 'warning-color-scheme';
          break; 
        } 
        case MessageBoxType.Error: { 
          this.icon = 'bell-alert';
          this.class = this.class + 'error-color-scheme';
          break; 
        } 
     } 
    }


    onOk() {
      this.dialogRef.close({result: 'ok'});
    }

    onCancel() {
      this.dialogRef.close({result: 'cancel'});
    }

    onYes() {
      this.dialogRef.close({result: 'yes'});
    }

    onNo() {
      this.dialogRef.close({result: 'no'});
    }

    onAccept() {
      this.dialogRef.close({result: 'accept'});
    }

    onReject() {
      this.dialogRef.close({result: 'reject'});
    }

}

