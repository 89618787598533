export type  EVERYONE = "all";
export type  READER = "reader";
export type  ADMIN = "all";
export type  DEVELOPER = "developer";
export type  HAS_PRIVILEGES = "privileges";
export type  HAS_SUGGESTED_PATTERNS_ACCESS = "suggested-patterns-access";
export type  HAS_ADMIN_PANEL_ACCESS = "admin-panel-access";
export type  HAS_DEV_PANEL_ACCESS = "dev-panel-access";
export const PERMISSIONS = {
    EVERYONE : "all",
    READER : "reader",
    ADMIN : "all",
    DEVELOPER : "developer",
    HAS_PRIVILEGES : "privileges",
    HAS_SUGGESTED_PATTERNS_ACCESS : "suggested-patterns-access",
    HAS_ADMIN_PANEL_ACCESS : "admin-panel-access",
    HAS_DEV_PANEL_ACCESS : "dev-panel-access",
}
