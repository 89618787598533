import { IUserProfile, UserProfile } from './user-profile';
import { Subscriber, ISubscriber } from './subscriber';

export interface IUser {
  UserName: string;
  Password: string;
  Id: string;
  CreatedOn: string;
  ProfileName: string;
  UserProfile: UserProfile;
  UserProfiles: UserProfile[];
  Subscribers: Subscriber[];
  AccountId: string;
  PreferredLanguage: string;
  AccountName: string;
  UserId: string;

}

export class User implements IUser {
  UserName: string = '';
  Password: string = '';
  Id: string = '';
  CreatedOn: string = '';
  ProfileName: string = '';
  UserProfile: UserProfile = {} as UserProfile;
  UserProfiles: UserProfile[] = [];
  Subscribers: Subscriber[] = [];
  AccountId: string = '';
  PreferredLanguage: string = '';
  AccountName: string = '';
  UserId: string = '';

  constructor(jsonObject?: IUser) {
    jsonObject && this.jsonToClassAdapter(jsonObject);
  }

  private jsonToClassAdapter(jsonObject: IUser) {
    Object.keys(this).forEach((prop) => {
      this[prop] = jsonObject[prop];
      if (prop === 'UserProfiles' && jsonObject['UserProfiles']) {
        this[prop] = (jsonObject[prop] as IUserProfile[]).map(adaptUserProfile);
      }
      if (prop === 'Subscribers' && jsonObject['Subscribers']) {
        this[prop] = (jsonObject[prop] as ISubscriber[]).map(adaptSubscribers);
      }
    });
  }

  public getUserName(): string {
    return `${this.UserName}`;
  }
}

function adaptUserProfile(userProfile: IUserProfile) {
  return new UserProfile(userProfile);
}

function adaptSubscribers(subscribers: ISubscriber) {
  return new Subscriber(subscribers);
}
