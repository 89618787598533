export interface ISupportedLanguages {
    he: boolean,
    ar: boolean,
    ru: boolean,
    am: boolean,
    en: boolean   
}

export class SupportedLanguages implements ISupportedLanguages {
    he: boolean = false;
    ar: boolean = false;
    ru: boolean = false;
    am: boolean = false;
    en: boolean = true;  

    constructor() {}

}

export interface ILanguage {
    Code: string,
    Description: string
}