/* tslint:disable:max-line-length */
import { AppSettings } from '@app/core/app-settings';
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id  : 'divider-0',
        type: 'divider'
    },
    {
        id   : 'dashboard',
        title: 'dashboard',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/dashboard',
        permission: 'privileges',
    },
    {
        id   : 'subscribers-list',
        title: 'subscribers-list',
        type : 'basic',
        icon : 'mat_solid:space_dashboard',
        link : '/subscribers-list',
        permission: 'all',
        // badge: {
        //     title  : '5 Alerts',
        //     classes: 'px-2 bg-red-600 text-black rounded'
        // }
    },
    {
        id  : 'divider-1',
        type: 'divider',
        permission: 'all',
    },
    // {
    //     id      : 'management',
    //     title   : 'Management',
    //     subtitle: 'Data management',
    //     type    : 'group',
    //     icon    : 'heroicons_outline:support',
    //     children: [
    //         {
    //             id   : 'management.subscriber-management',
    //             title: 'Subscriber Management',
    //             type : 'basic',
    //             icon : 'mat_solid:manage_accounts',
    //             link : '/subscriber-management'
    //         },
    //         {
    //             id   : 'management.user-management',
    //             title: 'User Management',
    //             type : 'basic',
    //             icon : 'mat_solid:supervised_user_circle',
    //             link : '/user-management'
    //         }
    //     ]
    // },
    {
        id   : 'management.subscriber-management',
        title: 'subscriber-management',
        type : 'basic',
        icon : 'mat_solid:manage_accounts',
        link : '/subscriber-management',
        permission: 'privileges',
    },
    {
        id   : 'management.user-management',
        title: 'user-management',
        type : 'basic',
        icon : 'mat_solid:supervised_user_circle',
        link : '/user-management',
        permission: 'privileges',
    },
    {
        id   : 'suggested-patterns',
        title: 'suggested-patterns',
        type : 'basic',
        icon : 'mat_solid:pattern',//monitor
        link : '/suggested-patterns',
        permission: 'suggested-patterns-access',
    },
    {
        id   : 'admin-panel',
        title: 'admin-panel',
        type : 'basic',
        icon : 'mat_solid:settings_suggest',
        link : '/admin-panel',
        permission: 'admin-panel-access',
    },
    {
        id   : 'dev-panel',
        title: 'dev-panel',
        type : 'basic',
        icon : 'mat_solid:code',
        link : '/dev-panel',
        permission: 'dev-panel-access',
    },
    {
        id  : 'divider-2',
        type: 'divider',
        permission: 'privileges',
    },
    {
        id : 'auroraview-image',
        type: 'basic',
        permission: 'all',
        image: 'assets/icons/av-logo-with-back.jpg',
    },
    {
        id : 'build-number',
        title: AppSettings.BuildVersion,
        type: 'basic',
        permission: 'all',
        build: true,
    }
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'mat_solid:space_dashboard',
        link : '/example'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'mat_solid:space_dashboard',
        link : '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'mat_solid:space_dashboard',
        link : '/example'
    }
];
