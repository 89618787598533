import { NgZone } from '@angular/core';
export interface ISensor {
    Id: string,
    Location: string,
    SensorType: string,
    SenseDescription: string,
    Sense: string,
    ZoneDescription: string,
    Zone: string,
    SensorName: string,
    DeviceId: string,
    SubscriberId: string,
    Feature: string,
    LastActivity: string,
    IsPaired: boolean,
    IsDirty: boolean,
    EspId: string,
    IsActive: boolean,
    LastKownState: string,
}

export class Sensor implements ISensor {
    Id: string = '';
    Location: string = '';
    SensorType: string = '';
    SenseDescription: string = '';
    Sense: string = '';
    ZoneDescription: string = '';
    Zone: string = '';
    SensorName: string = '';
    DeviceId: string = '';
    SubscriberId: string = '';
    Feature: string = '';
    LastActivity: string = '';
    IsPaired: boolean  = false;
    IsDirty: boolean  = false;
    EspId: string = '';
    IsActive: boolean  = false;
    LastKownState: string = '';

    constructor(jsonObj?: ISensor) {
        !!jsonObj && this.jsonToClassAdapter(jsonObj);
    }

    private jsonToClassAdapter(jsonObj: any) {
        for (const prop in this) this[prop] = jsonObj[prop];
    }
}