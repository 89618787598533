<!-- Button -->
<button
    mat-icon-button
    [matMenuTriggerFor]="userActions">
    <span class="relative">
        <img
            class="w-7 h-7 rounded-full"
            *ngIf="showAvatar && user.avatar"
            [src]="user.avatar">
        <mat-icon
            *ngIf="!showAvatar || !user.avatar"
            [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <div [class]="isRTL ? 'left-0' : 'right-0'"><span           
            class="absolute bottom-0 w-2 h-2 rounded-full"
            [ngClass]="{'mr-px mb-px': !showAvatar || !user.avatar,
                          'bg-green-500': user?.status === 'online',
                          'bg-amber-500': user?.status === 'away',
                          'bg-red-500': user?.status === 'busy',
                          'bg-gray-400': user?.status === 'not-visible'}">
            </span>
        </div>
    </span>
</button>

<mat-menu
    [xPosition]="'before'"
    #userActions="matMenu">
    <ng-container *transloco="let t">
        <button mat-menu-item>
            <span class="flex flex-col leading-none">
                <span>{{t('signed-in-as')}}</span>
                <span class="mt-1.5 text-md font-medium">{{user?.email}}</span>
            </span>
        </button>
        <mat-divider class="my-2"></mat-divider>
        <button
            mat-menu-item
            (click)="navigateToUserSettings()">
            <mat-icon [svgIcon]="'heroicons_outline:cog'"></mat-icon>
            <span>{{t('user-settings')}}</span>
        </button>
            <button
            *ngIf="!isAppInstalled()"
            class="user-area-menu-option"
            mat-menu-item
            (click)="installApp()">
            <mat-icon [svgIcon]="'heroicons_outline:download'"></mat-icon>
        <span>{{t('install-sense')}}</span>
        </button>
        <mat-divider class="my-2"></mat-divider>
        <button
            mat-menu-item
            (click)="signOut()">
            <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
            <span>{{t('sign-out')}}</span>
        </button>
    </ng-container>
</mat-menu>

<mat-menu
    class="user-status-menu"
    #userStatus="matMenu">
    <button
        mat-menu-item
        (click)="updateUserStatus('online')">
        <span class="w-4 h-4 mr-3 rounded-full bg-green-500"></span>
        <span>Online</span>
    </button>
    <button
        mat-menu-item
        (click)="updateUserStatus('away')">
        <span class="w-4 h-4 mr-3 rounded-full bg-amber-500"></span>
        <span>Away</span>
    </button>
    <button
        mat-menu-item
        (click)="updateUserStatus('busy')">
        <span class="w-4 h-4 mr-3 rounded-full bg-red-500"></span>
        <span>Busy</span>
    </button>
    <button
        mat-menu-item
        (click)="updateUserStatus('not-visible')">
        <span class="w-4 h-4 mr-3 rounded-full bg-gray-400"></span>
        <span>Invisible</span>
    </button>
</mat-menu>