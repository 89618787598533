import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '@app/core/api';
import { GenericHttpClientService } from 'core-services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User, IUser, StartupData } from '@app/models';

@Injectable({
    providedIn: 'root',
})
export class UsersService extends GenericHttpClientService<User, string> {
    users: User[] = [];

    getUsers(accountId?: string): Observable<User[]> {
        if (accountId) {
            return this.readAccountList(accountId);
        }
        return this.readList();
    }

    // Extended user includeds user profiles of all linked accounts and subscribers of all linked accounts
    getExtendedUser(id: string): Observable<User> {
        return this.readOne(id);
    }

    create(uri: string = API.subscriberApiUrl, item: User): Observable<User> {
        return super.create(API.userApiUrl.one, item);
    }

    deleteUser(username: string): Observable<User> {
        return super.delete(`${API.userApiUrl.one}/${username}`);
    }

    updateUser(uri: string = API.subscriberApiUrl, item: User): Observable<User> {
        return super.update(API.userApiUrl.one, item);
    }

    updateUserDetails(item: User): Observable<User> {
        return super.update(API.userApiUrl.update, item);
    }

    readOne(id: string): Observable<User> {
        return super.readOne(API.userApiUrl.user + '/' + id).pipe(map((data: IUser) => this.adapt(data)));
    }

    readList(): Observable<User[]> {
        return super.readList(API.userApiUrl.list).pipe(map((data: IUser[]) => this.adaptList(data)));
    }

    readAccountList(accountId?: string): Observable<User[]> {
        return super
            .readList(accountId ? `${API.userAccountApiUrl.accountlist}/${accountId}` : API.userAccountApiUrl.accountlist)
            .pipe(map((data: IUser[]) => this.adaptList(data)));
    }

    constructor(private httpClient: HttpClient) {
        super(httpClient);
    }

    private adapt(item: IUser): User {
        return new User(item);
    }

    private adaptList(items: IUser[]): User[] {
        return items.map(this.adapt);
    }
}
