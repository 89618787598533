export const Profiles = [ //TODO: is in use?
    {
        role: 'Viewer',
        name: 'Reader',
        description: ' user that can only view the linked account/s subscribers',
    },
    {
        role: 'Admin',
        name: 'Admin',
        description: ' user that can view the linked account/s subscribers and manage users and subscribers',
    },
];

export const roles = {
    Reader: {
        name: 'Reader',
        code: 'R',
        description: ' user that can only view the linked account/s subscribers',
    },
    Admin: {
        name: 'Admin',
        code: "A",
        description: ' user that can view the linked account/s subscribers and manage users and subscribers',
    },
    Developer: {
        name: 'Developer',
        code: "D",
        description: ' user that can view the entire system',
    },
};

export const AllAccountsCode = '-1';

export const Permissions = {
    AllUsers: 'All Users',
    Admin: 'Developer And Admin',
    Developer: 'Developer Only',
};
