import { NgModule } from '@angular/core';
import { Route, Routes, RouterModule } from '@angular/router';

import { CloseDialogOnRouteService } from '@app/helpers/guards/child.guard';
import { LayoutComponent } from '@app/core/layout/layout.component';
import { InitialDataResolver } from './app.resolvers';
// import { NoAuthGuard } from '@app/core/auth/guards/noAuth.guard';
// import { AuthGuard } from '@app/core/auth/guards/auth.guard';
import { AuthGuard } from '@app/helpers/guards/auth.guard';
import { AuthGuardAdmin } from '@app/helpers/guards/admin-auth.guard';
import { AuthGuardDev } from '@app/helpers/guards/dev-auth.guard';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
const appRoutes: Route[] = [
    // Redirect empty path to '/dashboard'
    { path: '', pathMatch: 'full', redirectTo: '/dashboard' },

    // Redirect signed in user to the '/example'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: '/dashboard' },

    // Auth routes for guests
    {
        path: '',
        // canActivate: [NoAuthGuard],
        // canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'confirmation-required',
                loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then((m) => m.AuthConfirmationRequiredModule),
            },
            {
                path: 'forgot-password',
                loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then((m) => m.AuthForgotPasswordModule),
            },
            {
                path: 'reset-password',
                loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then((m) => m.AuthResetPasswordModule),
            },
            {
                path: 'sign-in',
                loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then((m) => m.AuthSignInModule),
            },
            {
                path: 'sign-up',
                loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then((m) => m.AuthSignUpModule),
            },
        ],
    },

    // Auth routes for authenticated users
    {
        path: '',
        // canActivate: [AuthGuard],
        // canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'sign-out',
                loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then((m) => m.AuthSignOutModule),
            },
            {
                path: 'unlock-session',
                loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then((m) => m.AuthUnlockSessionModule),
            },
        ],
    },

    // Admin routes
    {
        path: '',
        // canActivate: [AuthGuard],
        // canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {
                path: 'user-management',
                canActivate: [AuthGuardAdmin],
                loadChildren: () => import('@modules/management/user-management/user-management.module').then((m) => m.UserMangementModule),
            },
            {
                path: 'subscriber-management',
                canActivate: [AuthGuardAdmin],
                loadChildren: () => import('@modules/management/subscriber-management/subscriber-management.module').then((m) => m.SubscriberManagementModule),
            },
            {
                path: 'suggested-patterns',
                canActivate: [AuthGuardAdmin],
                loadChildren: () => import('@modules/management/suggested-patterns/suggested-patterns.module').then((m) => m.SuggestedPatternsModule),
            },
            {
                path: 'admin-panel',
                canActivate: [AuthGuardAdmin],
                loadChildren: () => import('@modules/management/admin-panel/admin-panel.module').then((m) => m.AdminPanelModule),
            },
            {
                path: 'dev-panel',
                canActivate: [AuthGuardDev],
                loadChildren: () => import('@modules/management/dev-panel/dev-panel.module').then((m) => m.DevPanelModule),
            },
            {
                path: 'dashboard',
                canActivate: [AuthGuardAdmin],
                loadChildren: () => import('app/modules/admin/dashboard/dashboard.module').then((m) => m.DashboardModule),
            },
            {
                path: 'subscribers-list',
                // canActivate: [AuthGuard],
                loadChildren: () => import('app/modules/admin/subscribers/subscribers-list.module').then((m) => m.SubscribersModule),
            },
            {
                path: 'user-settings',
                // canActivate: [AuthGuard],
                loadChildren: () => import('@modules/user/user-settings/user-settings.module').then((m) => m.UserSettingsModule),
            },
            {
                path: 'testing',
                canActivate: [AuthGuardDev],
                loadChildren: () => import('@modules/testing/testing.module').then((m) => m.TestingModule),
            },
            {
                path: 'logs-reader',
                canActivate: [AuthGuard],
                loadChildren: () => import('@modules/testing/logs-reader/logs-reader.module').then((m) => m.LogsReaderModule),
            },
        ],
    },
    {
        path: '**',
        redirectTo: '',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
