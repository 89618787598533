//TODO: is in use?

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-pwabottom-sheet',
  templateUrl: './pwabottom-sheet.component.html',
  styleUrls: ['./pwabottom-sheet.component.scss']
})
export class PWABottomSheetComponent implements OnInit {

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) private data: boolean,
    private matBottomSheetRef: MatBottomSheetRef
  ) { }

  ngOnInit(): void {
  }

  public installPWA() {
    this.data = true;
    this.matBottomSheetRef.dismiss();
  }
  ngOnDestroy() {
    this.matBottomSheetRef.dismiss(this.data);
  }
  public onCloseClick() {
    this.matBottomSheetRef.dismiss();
  }
}
