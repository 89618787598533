<!-- Loading bar -->
<fuse-loading-bar [dir]="isRTL ? 'rtl' : 'ltr'"></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation  
    class="dark bg-gray-900 print:hidden"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation.default"
    [opened]="!isScreenSmall"
    [isRTL]="isRTL"
    [currentAccountId]="currentAccountId"
    >
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <div class="flex items-center w-full p-4 pl-6">
            <!-- Logo -->
            <div class="flex items-center justify-center">
                <img
                    class="w-24"
                    src="assets/icons/sense-logo.png">
            </div>
            <!-- Components -->
            <div class="flex items-center"
                [class]="isRTL ? 'mr-auto' : 'ml-auto'">
                <!-- <notifications></notifications> -->
                <user [showAvatar]="false" [isRTL]="isRTL"></user>
            </div>
        </div>
        <!-- User -->
        <div class="flex flex-col items-center w-full">
            <div class="relative w-24 h-24">
                <img
                    class="w-full h-full rounded-full"
                    *ngIf="false && user.avatar"
                    [src]="user.avatar"
                    alt="User avatar">
                <mat-icon
                    class="icon-size-24"
                    *ngIf="true || !user.avatar"
                    [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
            </div>
            <div class="flex flex-col items-center justify-center w-full mt-6">
                <div
                    class="w-full whitespace-nowrap text-ellipsis overflow-hidden text-center leading-normal font-medium">
                    {{user?.email}}
                </div>
                <div
                    class="w-full mt-0.5 whitespace-nowrap text-ellipsis overflow-hidden text-center text-md leading-normal font-medium text-secondary">
                    {{user?.name}}
                </div>
            </div>

            <div [class]="isRTL ? 'pt-2 w-full mr-12' : 'pt-2 w-full px-2'" *transloco="let t">
                <!-- (mouseenter)="openAccountsMenu()" -->
                <button
                    mat-button
                    [matMenuTriggerFor]="AccountMenu">
                    <mat-icon svgIcon="mat_outline:business"></mat-icon>
                    <span [class]="isRTL ? 'mr-4' : 'ml-4'">{{accountName}} </span>
                    <mat-icon>arrow_drop_down</mat-icon>
                </button>
                <mat-menu #AccountMenu="matMenu" class="accounts-list">
                    <div *ngIf="profiles.length > 0">
                        <button
                            mat-menu-item
                            class="account-option"
                            *ngFor="let profile of profiles; trackBy: trackByMethod"
                            (click)="setAccount(profile)">{{profile.AccountName}}</button>
                        <button
                            *ngIf="isDeveloper"
                            mat-menu-item
                            class="account-option"
                            (click)="setAccount({AccountName: 'All accounts', AccountId: allAccountsId})">{{t('all-accounts')}}</button>
                    </div>
                </mat-menu>
            </div>

        </div>

        
    </ng-container>
    <!-- Navigation footer hook -->
    <ng-container fuseVerticalNavigationContentFooter>
        <!-- <div class="flex flex-0 items-center justify-center h-16 pr-6 pl-2 mt-2 mb-4 opacity-12">
            <img
                class="max-w-36"
                src="assets/icons/sense-logo.png">
        </div> -->
        <!-- absolute inset-x-0 bottom-15 opacity-12 -->
        <!--<div class="absolute bottom-15 w-full opacity-4">
            <img class="w-30 m-auto rounded-full shadow-inner" src="assets/icons/av-logo-with-back.jpg">
        </div>
        <div class="absolute bottom-5 w-full">
            <div class="text-center">{{ buildVersion }}</div>
        </div>-->
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0" [dir]="isRTL ? 'rtl' : 'ltr'">

    <!-- Header -->
    <div
        class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <!-- Navigation toggle button -->
        <button
            mat-icon-button
            (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
        </button>

        <!-- Sense logo -->
        <div class="flex-auto flex items-center justify-center sm:invisible">
            <img class="w-22"
                src="assets/icons/sense-logo.png">
        </div>

        <!-- Components -->
        <div 
            class="flex items-center pl-2 space-x-0.5 sm:space-x-2"
            [class]="isRTL ? 'mr-auto' : 'ml-auto'"
            >
            <languages></languages>
            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            <!-- <search [appearance]="'bar'"></search> -->
            <!-- <shortcuts></shortcuts> -->
            <!-- <messages></messages> -->
            <!-- <button
                class="lg:hidden"
                mat-icon-button
                (click)="quickChat.toggle()">
                <mat-icon [svgIcon]="'heroicons_outline:chat-alt-2'"></mat-icon>
            </button> -->
        </div>

    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <!-- <div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">Fuse &copy; {{currentYear}}</span>
    </div> -->

</div>

<!-- Quick chat -->
<!-- <quick-chat #quickChat="quickChat"></quick-chat> -->