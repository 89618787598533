import { Component, HostListener, OnInit } from '@angular/core';
import { PWAInstallService } from '@app/services/pwa/pwainstall.service';
import { NotificationMiddlewareService } from '@app/services/middleware/notification-middleware.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    constructor(
        private _pwaInstall: PWAInstallService,
        private _notificationMiddleware: NotificationMiddlewareService
    ) {}

    @HostListener('window:beforeinstallprompt', ['$event'])
    onBeforeInstallPrompt(e) {
        const existingUser = JSON.parse(localStorage.getItem('existingUser'));
        e.preventDefault();
        this._pwaInstall.promptEvent = e;
        if (!existingUser) {
            e.preventDefault();
            this._pwaInstall.setUpBottomSheet();
        }
    }

    @HostListener('window:appinstalled', ['$event'])
    onAppInstalled(e) {
        this._pwaInstall.trackInstalled();
    }

    ngOnInit(): void {
        this._notificationMiddleware.init();
    }
}
