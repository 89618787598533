export interface IIoTEvent {
  deviceId: string;
  sensor: string;
  sensorType: string;
  sense: string;
  value: string;
  normalState: any;
  zone: string;
  isWarning: string;
  normalMin: string;
  normalMax: string;
  unitOfMeasure: string;
  timeLocal: Date;
  timeUTC: Date;
}

export interface IRouteEvevnt {
  from: Date;
  until: Date;
  zone: string;
  senses: string[];
}

export class RouteEvent implements IRouteEvevnt {
  from: Date;
  until: Date;
  zone: string;
  senses: string[];
}

// Pay attention: the class is extending RoWConfig, so it can be placed inside Dynamic Material Table
export class IoTEvent implements IIoTEvent {
  deviceId: string;
  sensor: string;
  sensorType: string;
  sense: string;
  value: any;
  normalState: any;
  zone: string;
  isWarning: string;
  normalMin: any;
  normalMax: any;
  unitOfMeasure: string;
  timeLocal: Date;
  timeUTC: Date;


  constructor(jsonObj: IIoTEvent) {
    this.jsonToClassAdapter(jsonObj);
  }

  private jsonToClassAdapter(jsonObj: any) {
    for (const prop in jsonObj) {
      if ((prop !== 'timeLocal') && (prop !== 'timeUTC')) {
        this[prop] = jsonObj[prop];
      } else {
        this[prop] = new Date (jsonObj[prop]);
      }
    }
  }

  private isPrimitive(prop: object): boolean {
    if (typeof prop === 'string' || typeof prop === 'number' || typeof prop === 'boolean') {
      return true;
    } else { return false; }
  }

  alertIndicator(): string {
    if ((this.value <= 0)) { return 'red'; } // Here we would compare against real threshold in the future...
    return 'green';
  }
}

// IIoTEvent - Interface Type Guard
export function isIoTEvent(inputObj: any): inputObj is IIoTEvent {
  return 'deviceId' in inputObj && typeof inputObj['deviceId'] === 'string' &&
         'sensor' in inputObj && typeof inputObj['sensor'] === 'string' &&
         'sensorType' in inputObj && typeof inputObj['sensor'] === 'string' &&
         'sense' in inputObj && typeof inputObj['sense'] === 'string' &&
         'value' in inputObj &&
         'normalState' in inputObj &&
         'zone' in inputObj && typeof inputObj['zone'] === 'string' &&
         'isWarning' in inputObj && typeof inputObj['isWarning'] === 'string' &&
         'normalMin' in inputObj &&
         'normalMax' in inputObj &&
         'unitOfMeasure' in inputObj && typeof inputObj['unitOfMeasure'] === 'string' &&
         'timeLocal' in inputObj &&
         'timeUTC' in inputObj 
}

// IIoTEventsList - Interface Type Guard
export function isIoTEventsList(value: any): value is IIoTEvent[] {
  for (const entry of value) {
    if (!isIoTEvent(entry)) { return false; }
  }
  return true;
}
