// Tried to combine HttpErrorResponse into the global error handler per https://medium.com/@aleixsuau/error-handling-angular-859d529fa53a
// No consistent behavior when it comes to logging and Snackbar (even mentioned in the blogpost comments)
// so - the logger and snackbar were implemented here

import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';

import { Observable, of, EMPTY, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Location } from '@angular/common'; // In case we want a url and not using router...
import { NotificationBarService } from 'notification-bar';
import { NGXLogger, NgxLoggerLevel } from 'core-services';
import { CurrentUserService } from '@app/auth/current-user.service';
import { ICurrentUser } from '@app/auth/auth.model';
import { AuthService } from '@app/auth/auth.service';
import * as utils from '@app/helpers/general-helper';

@Injectable()
export class HttpErrorsInterceptor implements HttpInterceptor {

  constructor(private injector: Injector, private location: Location, private logger: NGXLogger,
    private router: Router,
    private notifier: NotificationBarService, private currentUserService: CurrentUserService,
    private authService: AuthService,) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(request).pipe(
      catchError((err) => {
        const url = this.injector.get(Router).url; // url provided by the router
        const path = this.location.path(); // url provided by the location
        const currUser: ICurrentUser = this.currentUserService.retrieve();

        if (err instanceof HttpErrorResponse) {
          if ([401].includes(err.status)) {
            if (this.authService.isAuthUserLoggedIn()) {
              this.authService.logout();
            }
            this.notifier.notifyError('Your Session has Expired, Please login.'); // Notification Bar
            setTimeout(() => { this.router.navigate(['sign-in']); }, 500);
            // if (!utils.isNullorUndefined(currUser)) { this.authService.logout(); } // TBD... This will clear the LocalStorage - I want it for debugging...
            // console.error('HttpErrorsInterceptor Intercepted error: [401] response from server. Please login to make requested call.' + '  URL: ' + err.url); // Debug 
            // this.notifier.notifyError('Error: [401] response from server. Please login to make requested call.'); // Notification Bar
          } else if ([403].includes(err.status)) {  
            // if (!utils.isNullorUndefined(currUser)) { this.authService.logout(); }  // TBD... This will clear the LocalStorage - I want it for debugging...
            // console.error('HttpErrorsInterceptor Intercepted error: [403] response from server. You are not authorized to make this call.' + '  URL: ' + err.url); // Debug 
            this.notifier.notifyError('Error: [403] response from server. You are not authorized to make this call.'); // Notification Bar
          } else {
            // Do something else...
            console.log(err);
          }
          if (!utils.isNullorUndefined(err) && !utils.isNullorUndefined(err.error) && !utils.isNullorUndefined(err.error.message)) {
            console.error('HttpErrorsInterceptor Intercepted error: ' + err.status + ' - ' + err.error.message + '  URL: ' + err.url); // No need if the Logger == Console... 
            // this.notifier.notifyError(err.error.message + ' error occured. Check your console for details...'); // Notification Bar
          } else {
            // Logger
            if (currUser) {
              // this.logger.log('Logger', {
              //   message: 'HttpErrorsInterceptor Intercepted error: ', // + err.status + ' - ' + err.error?.message + '  URL: ' + err.url,
              //   date: new Date(),
              //   // level: NgxLoggerLevel.ERROR,
              //   // additionalInfo: JSON.stringify(err),
              //   additionalInfo: {
              //     'err-name': err.name,
              //     'err-message': err.message,
              //     'err-status': err.status,
              //     'err-status-text': err.statusText,
              //     'err-url': err.url,
              //   },
              //   // additionalInfo: '',
              //   // additionalParams: []
              // });
            }
          }
          // return EMPTY;
          return throwError(err?.error);
        }
      })
    );
  }
}
