export interface IAlertTrigger {
    Id?: string,
    SubscriberId: string,
    AlertTriggerCode: string,
    DayCode: string,
    Value: string, 
    Order: string, 
}

export class AlertTrigger implements IAlertTrigger {
    Id?: string = "";
    SubscriberId: string = "";
    AlertTriggerCode: string = "";
    DayCode: string = "";
    Value: string = "";
    Order:string = "";
  
    constructor(jsonObject: IAlertTrigger) {
        this.jsonToClassAdapter(jsonObject);
    }

    private jsonToClassAdapter = (jsonObject: IAlertTrigger) => Object.keys(this).forEach(prop => this[prop] = jsonObject[prop]);
}