export const languageOptions = {
    defaultLang: 'en',
    fallbackLang: 'en',
    availableLangs: [
      {
          id   : 'en',
          label: 'English'
      },
      {
          id   : 'he',
          label: 'עברית'
      },
      {
          id   : 'ar',
          label: 'العربية'
      },
      {
          id   : 'ru',
          label: 'Русский'
      },
      {
          id   : 'am',
          label: 'አማርኛ'
      },
    ]
  }