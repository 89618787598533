import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { BooleanInput } from '@angular/cdk/coercion';
import { Subject, take, takeUntil } from 'rxjs';
import { User } from 'app/core/user/user.types';
import { UserService } from 'app/core/user/user.service';
import * as UserConstants from '@app/constants/user-constants';
import { PWAInstallService } from '@app/services/pwa/pwainstall.service';
import { AppDataService } from '@app/services/store/app-data.service';
import { IAppData } from '@app/models';
import { AuthService } from '@app/auth/auth.service';
import { AppSettings } from '@app/core/app-settings';

@Component({
    selector       : 'user',
    templateUrl    : './user.component.html',
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs       : 'user'
})
export class UserComponent implements OnInit, OnDestroy
{
    /* eslint-disable @typescript-eslint/naming-convention */
    static ngAcceptInputType_showAvatar: BooleanInput;
    /* eslint-enable @typescript-eslint/naming-convention */

    @Input() showAvatar: boolean = true;
    @Input('isRTL') isRTL: boolean = true;
    user: User;
    clientBuildString: string = "";
    serverBuildString: string = "";

    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private _appData: IAppData;
    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        private _userService: UserService,
        private _pwaInstall: PWAInstallService,
        private _appDataService: AppDataService,
        private _authService: AuthService,
    )
    {
        this._appDataService.appData$.pipe(take(1)).subscribe(data => this._appData = data);
        this.clientBuildString = AppSettings.BuildVersion;
        this.serverBuildString = this._authService.getServerBuildString();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to user changes
        this._userService.user$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: User) => {
                this.user = user;

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the user status
     *
     * @param status
     */
    updateUserStatus(status: string): void
    {
        // Return if user is not available
        if ( !this.user )
        {
            return;
        }

        // Update the user
        // this._userService.update({
        //     ...this.user,
        //     status
        // }).subscribe();
    }

    /**
     * Sign out
     */
    signOut(): void
    {
        this._router.navigate(['/sign-out']);
    }

    navigateToUserSettings() {
        this._router.navigate(['/user-settings']);
    }

    navigateToTesting() {
        this._router.navigate(['/testing']);
    }

    navigateTo(event: Event) {
        let destination: string = (<HTMLInputElement>event.target).name;
        this._router.navigate([`/${destination}`]);
    }

    /**
     * checks if loggedin user is developer
     * @returns 
     */

    isDeveloper():boolean {
        return this._appData.UserData.UserProfiles[0].ProfileName === UserConstants.roles.Developer.name;
    }

    /**
    * installs PWA app
    */
    installApp() {
        this._pwaInstall.installPWA();
    }

    /**
     * checks if PWa app is installed
     * @returns true for installed, false for not installed
     */
    isAppInstalled() {
        return this._pwaInstall.isAppInstalled;
    }
}
