import { ISensor, Sensor } from "./device-sensor";
import { SensorMCUProximity } from './sensor-mcu-proximity';


export interface IDevice {
    Id: string,
    SerialNumber: string,
    SubscriberId: string,
    DeviceSensors: Sensor[],
    DeviceEsps?: Sensor[],
    SensorMCUProximity?: SensorMCUProximity[],
    LifeBeaconFrequency: number,
    IsDirty: boolean,
    IsExternalDevice?: boolean
}

export class Device implements IDevice {
    Id: string = '';
    SerialNumber: string = '';
    SubscriberId: string = '';
    IsDirty: boolean  = false;
    IsExternalDevice?: boolean  = false;
    SensorMCUProximity?: SensorMCUProximity[] = [];
    DeviceSensors: Sensor[] = [];
    DeviceEsps?: Sensor[] = [];
    LifeBeaconFrequency: number;

    constructor(jsonObj: IDevice) {
        this.jsonToClassAdapter(jsonObj);
    }

    private jsonToClassAdapter(jsonObj: any) {
        Object.keys(this).forEach(prop => {
            this[prop] = jsonObj[prop];
            if (prop === "DeviceSensors" && jsonObj["DeviceSensors"]) {
                let sensorsArray = (jsonObj[prop] as ISensor[]).map(adaptSensor);
                this[prop] = sensorsArray;
            }
            if (prop === "SensorMCUProximity" && jsonObj["SensorMCUProximity"]) {
                let SensorProximityArray = (jsonObj[prop] as SensorMCUProximity[]).map(adaptSensorProximity);
                this[prop] = SensorProximityArray;
            }
        })
    }
}

function adaptSensor(sensor: ISensor) {
    return new Sensor(sensor);
}

function adaptSensorProximity(sensorProoxmity: SensorMCUProximity) {
    return new SensorMCUProximity(sensorProoxmity);
}

//#region Device - Interface Type Guard
export function isDevice(inputObj: any): inputObj is IDevice {
    return 'Id' in inputObj && typeof inputObj['Id'] === 'string' &&
        'SerialNumber' in inputObj && typeof inputObj['SerialNumber'] === 'string' &&
        'SubscriberId' in inputObj && typeof inputObj['SubscriberId'] === 'string'
        ;
}

export function isDevicesList(value: any): value is IDevice[] {
    for (const entry of value) {
        if (!isDevice(entry)) { return false; }
    }
    return true;
}

export interface IDeviceComponents {
    SensorNames: string[],
    Wearables: string[],
}
//#endregion