import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationBarComponent } from './notification-bar.component';
import { NotificationBarService } from './notification-bar.service';

import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    MatToolbarModule,
    MatDialogModule,
    MatIconModule,
    MatSnackBarModule
  ],
  declarations: [NotificationBarComponent],
  exports: [MatDialogModule, MatCardModule, MatToolbarModule, NotificationBarComponent, MatIconModule, MatSnackBarModule],
  providers: [NotificationBarService]
})
export class NotificationBarModule { }



