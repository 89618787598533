import { NgModule } from '@angular/core';
import { CoreModule } from '@core/core.module';
import { SharedModule } from '@shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, HammerModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common'; // In case we want a url and not using router...
import { OverlayContainer } from '@angular/cdk/overlay'; // support themes in MatDialogs
import 'hammerjs'; // Could also (either or) be done inside polyfills.ts
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../../environments/environment';
import { AvailableUpdateDialog, UpdateService } from '../services/pwa/update-app/update.service';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';
import { FuseModule } from '../../@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { appConfig } from 'app/core/config/app.config';
import { mockApiServices } from 'app/helpers/mock-api';
import { LayoutModule } from 'app/core/layout/layout.module';
// import { appRoutes } from 'app/app.routing';

const routerConfig: ExtraOptions = {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
};

@NgModule({
    declarations: [AppComponent, AvailableUpdateDialog],
    imports: [
        HammerModule,
        CoreModule,
        SharedModule,
        AppRoutingModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerImmediately', //'registerWhenStable:30000'
        }),
        BrowserModule,
        BrowserAnimationsModule,

        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),

        // Layout module of your application
        LayoutModule,

        // 3rd party modules that require global configuration via forRoot
        MarkdownModule.forRoot({}),
    ],
    providers: [
        Location,
        { provide: MAT_DATE_LOCALE, useValue: 'en-IL' },
        UpdateService,
        { provide: LocationStrategy, useClass: PathLocationStrategy }, // In case we want a url and not using router...
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer, overlayContainer: OverlayContainer) {
        // Latest mdi.svg can be downloaded at https://dev.materialdesignicons.com/getting-started/angular#angular-material
        matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/mdi.svg')); // Or whatever path you placed mdi.svg at
        // Themese supported in dialogs
        overlayContainer.getContainerElement().classList.add('black-theme');
        overlayContainer.getContainerElement().classList.add('dark-theme');
        overlayContainer.getContainerElement().classList.add('default-theme');
        overlayContainer.getContainerElement().classList.add('light-theme');
        overlayContainer.getContainerElement().classList.add('nature-theme');
    }
}
