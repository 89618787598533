import { MessageBoxComponent } from './message-box.component';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxButton, IMessageBoxData, MessageBoxType } from './message-box.model';

@Injectable({
  providedIn: 'root'
})
export class MessageBoxService {
  constructor(private dialog: MatDialog) {}

  openDialog(data: IMessageBoxData): any {
    const mb: IMessageBoxData = this.validateData(data);
    if (mb === null) { return; }

    return this.dialog.open(MessageBoxComponent, {
      width: data.width || '25%',
      panelClass: [],
      data: {
        type: mb.type || MessageBoxType.Notification,
        title: mb.title || 'Notification',
        message: mb.message,
        info: mb.info,
        button: mb.button || MessageBoxButton.Ok,
        overlayClick: mb.overlayClick || false
      }
    });
  }

  private validateData(data: IMessageBoxData): any {
    let mb: IMessageBoxData;
    mb = data;

    if (mb.message === null || mb.message === undefined || mb.message === '') { return null; }

    if (mb.title === null || mb.title === undefined) { mb.title = ''; }
    if (mb.info === null || mb.info === undefined) { mb.info = ''; }
    if (mb.overlayClick === null || mb.overlayClick === undefined) { mb.overlayClick = true; }
    if (mb.button === null || mb.button === undefined) { mb.button = MessageBoxButton.Ok; }
    if (mb.width === null || mb.width === undefined) { mb.width = '25%'; }

    return mb;
  }
}
