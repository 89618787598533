// clean old design

import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { MessageBoxModule, MessageBoxComponent, MessageBoxService } from 'message-box';
// import { httpInterceptorsProviders } from 'core-services'; I'd rather use a local instance, customized for the project...
import { httpInterceptorsProviders } from '@app/helpers/interceptors/interceptors-providers.index';
import { LoggerModule, NgxLoggerLevel } from 'core-services'; // Central Logger
import { GlobalErrorHandler } from '@core/global-error-handler'; // Global Error Handler
import { NotificationBarModule, NotificationBarComponent, NotificationBarService } from 'notification-bar';
import { StorageModule } from 'core-services'; // IndexedDB, LocalStorage

// Support CurrentUser functionality -> UI
import { CurrentUserService } from '@app/auth/current-user.service';
import { ICurrentUser } from '@app/auth/auth.model';
import { AuthService } from '@app/auth/auth.service';

// Configuration Related
import { environment } from '../../environments/environment'; // during build, this was overidden with the appropriate environment.NAME file
import { ConfigService, ENV_NAME, ENV_FULL_NAME } from 'core-services';
import { API } from '@app/core/api';

// used to create fake backend
import { fakeBackendProvider } from '@app/helpers/mock-api/fake-backend/fake-backend';

import { Optional, SkipSelf } from '@angular/core';
// import { AuthModule } from 'app/core/auth/auth.module';
import { IconsModule } from 'app/core/icons/icons.module';
import { TranslocoCoreModule } from 'app/core/transloco/transloco.module';

// The below is getting certain tasks (config data, initialize loggers, initialize current user settings)
// Can be used for triggerring anything prior to app launch (also could be based on external params)
export function appInit(config: ConfigService, auth: AuthService) {
  const cfgPromise = config.loadAppSettings().then((data) => {});
  const authPromise = auth.loadLastUser().then((u) => {});
  return () => Promise.all([cfgPromise, authPromise]);
}

 // Logger Object Content sent to the log function
export interface INGXLoggerMetadata {
  message?: any;
  level: NgxLoggerLevel;
  timestamp?: string;
  fileName?: string;
  lineNumber?: number;
  columnNumber?: number;
}

// So we can inject the environment name to injectable services and components
export const EnvName = { provide: ENV_NAME, useValue: environment.envName };
export const EnvFullName = { provide: ENV_FULL_NAME, useValue: environment.envFullName };

@NgModule({
  declarations: [
  ],
  imports: [
    // vendor
    BrowserModule,
    HammerModule,
    BrowserAnimationsModule,
    RouterModule,
    HttpClientModule,
    CommonModule,
    


    // local
    LoggerModule.forRoot({
      serverLoggingUrl: API.loggerApiUrl,
      level: environment.logLevel,
      serverLogLevel: environment.serverLogLevel}),    
    MessageBoxModule,
    NotificationBarModule,
    // Core Services
    StorageModule.forRoot({
      LSPrefix: 'iot-ct/',
      IDBDBName: 'iot-ct'
    }),
    // AuthModule,
    IconsModule,
    TranslocoCoreModule
  ],
  entryComponents: [
    MessageBoxComponent,
    NotificationBarComponent
  ],    
  exports: [
    CommonModule,
    MessageBoxComponent,
    NotificationBarComponent
  ],
  providers: [
    httpInterceptorsProviders,
    NotificationBarService,
    MessageBoxService,
    AuthService,
    CurrentUserService,
      { provide: ErrorHandler, useClass: GlobalErrorHandler },
    ConfigService, EnvName, EnvFullName,
    {'provide': APP_INITIALIZER, 'useFactory': appInit, 'deps': [ConfigService, AuthService], 'multi': true},

    // Disable the below to access real-life backend
    // fakeBackendProvider,
  ]
})

export class CoreModule {
      /**
     * Constructor
     */
      constructor(
        @Optional() @SkipSelf() parentModule?: CoreModule
    )
    {
        // Do not allow multiple injections
        if ( parentModule )
        {
            throw new Error('CoreModule has already been loaded. Import this module in the AppModule only.');
        }
    }
}
