// Attach various Headers to Http request

import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';

import { Observable } from 'rxjs';

@Injectable()
export class HeadersInterceptor implements HttpInterceptor {

  constructor() {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Content-Type Header
    if (!req.headers.has('Content-Type')) {
        req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
    }
    // CORS
    // req = req.clone({ headers: req.headers.set('Access-Control-Allow-Origin', '*') });
    // Accept Header
    // Accept 'image/svg+xml' for successfully loading mdi.svg - if not u will get in production HTTP-406
    req = req.clone({ headers: req.headers.set('Accept', ['application/json','image/svg+xml']) });
    return next.handle(req);
  }
}
