import { Component, HostListener, OnInit, Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { PWABottomSheetComponent } from '@app/modules/auth/pwabottom-sheet/pwabottom-sheet.component';

@Injectable({
    providedIn: 'root',
})
export class PWAInstallService {
    public promptEvent;
    isAppInstalled = false;
    choiceMade = false;

    constructor(private matBottomSheet: MatBottomSheet) {}

    trackInstalled() {
        this.isAppInstalled = true;
    }

    setUpBottomSheet() {
        if (this.shouldInstall()) {
            let buttonPWAPressed = false;
            let bottomSheet = this.matBottomSheet.open(PWABottomSheetComponent, {
                data: buttonPWAPressed,
                disableClose: true,
                hasBackdrop: false,
            });
            bottomSheet.afterDismissed().subscribe((isInstallPWA) => {
                if (isInstallPWA) {
                    this.installPWA();
                }
            });
        }
    }

    public installPWA() {
        this.choiceMade = true;
        this.promptEvent.prompt();
    }

    public shouldInstall(): boolean {
        return !this.choiceMade && !this.isRunningStandalone() && this.promptEvent && this.isMobile() && !this.isAppInstalled;
    }

    public isMobile(): boolean {
        const uaString = navigator.userAgent.toLowerCase();
        return /mobile/.test(uaString);
    }

    public isRunningStandalone(): boolean {
        return window.matchMedia('(display-mode: standalone)').matches;
    }
}
