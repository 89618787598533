import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import * as UserConstants from '@app/constants/user-constants';
import { AuthGuardService } from './auth-guard.service';

@Injectable({ providedIn: 'root' })
export class AuthGuardAdmin implements CanActivate {
    constructor(private _authGuardService: AuthGuardService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this._authGuardService.canActivate(UserConstants.Permissions.Admin, route, state);
    }
}
