import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { IIoTEvent, IoTEvent, isIoTEventsList } from '@app/models';
import { GenericHttpClientService, guard } from 'core-services';

@Injectable()
export class IotEventsService extends GenericHttpClientService<IoTEvent, string> {
    readEventsList(uri: string): Observable<IIoTEvent[]> {
        return super.readList(uri).pipe(
            map(guard(isIoTEventsList, 'Server response does not match expected schema for User data-structure')),
            tap((response) => {
                // Log here if you want, or do anything that is NOT altering the stream (use map to alter stream)
            }),
            catchError(this.handleError)
        );
    }

    readEvent(uri: string): Observable<IIoTEvent> {
        return super.readOne(uri).pipe(
            tap((response) => {
                // Log here if you want, or do anything that is NOT altering the stream (use map to alter stream)
            }),
            catchError(this.handleError)
        );
    }

    // Returns a list of IoT Events DTO classes
    readEventsDTO(uri: string): Observable<IoTEvent[]> {
        return super.readList(uri).pipe(
            map((data: any[]) => data.map((item) => this.adapt(item))),
            tap((response) => {
                // Log here if you want, or do anything that is NOT altering the stream (use map to alter stream)
                // console.log(response);
            }),
            catchError(this.handleError)
        );
    }

    // Returns a list of IoT Events DTO classes as Promise
    readEventsPromise(uri: string): Promise<any> {
        return super
            .readList(uri)
            .pipe(
                map((data: any[]) => data.map((item) => this.adapt(item))),
                catchError(this.handleError)
            )
            .toPromise();
    }

    private adapt(item: any): IoTEvent {
        // console.log(item);
        return new IoTEvent(item);
    }

    // The part of generic http error will NOT kick-in, since I have generic http errors interceptors...
    private handleError(error: HttpResponse<any>) {
        console.error(error);
        return throwError(error || 'Mapping error during response');
    }
}
