// need to remove comments
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { ConfigService } from '@app/services';
import { tap } from 'rxjs';
import { StartupData } from '@app/models';
import { LanguageService } from '@app/services/app-services/language.service';
import { AppDataService } from '@app/services/store/app-data.service';
import { CurrentUserService } from '@app/auth/current-user.service';
import { AuthService } from '@app/auth/auth.service';

@Injectable({
    providedIn: 'root',
})
export class InitialDataResolver implements Resolve<any> {  
    /**
     * Constructor
     */
    constructor(
        private _navigationService: NavigationService,
        private configService: ConfigService,
        private _languageService: LanguageService,
        private _appDataService: AppDataService,
        private _currentUserService: CurrentUserService,
        private _authService: AuthService,
        private _router: Router,
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Use this resolver to resolve initial mock-api for the application
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        // Fork join multiple API endpoint calls to wait all of them to finish
        if (!this._authService.isAuthUserLoggedIn()) {
            this._router.navigate(['/sign-in']);          
        } else {
            const defaultAccountId = this._currentUserService.retrieve()?.defaultAccountId;
            return forkJoin([
                this._navigationService.get(),
                this.configService.readOne(defaultAccountId).pipe(
                    tap((data) => {
                        console.log("%c Startup Data: ", 'background: darkgreen; color: #bada55', data);
                        const preferredLanguage = data.UserData.UserProfiles[0].PreferredLanguage;
                        this._languageService.setDefaultLanguageLoadStatus(preferredLanguage);
                        this._languageService.setActiveLang(preferredLanguage);
                        this._appDataService.setStartupData(data);
                    })
                ),
            ]);
        }
    }
}
