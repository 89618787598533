// clean old design

import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Location } from '@angular/common'; // In case we want a url and not using router...
import { NGXLogger } from 'core-services';
import { NotificationBarService } from 'notification-bar';
import {HttpHeaders } from '@angular/common/http';
import { ICurrentUser } from '@app/auth/auth.model';
import { CurrentUserService } from '@app/auth/current-user.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandler extends ErrorHandler {

  constructor(private injector: Injector, private location: Location, private logger: NGXLogger, private notifier: NotificationBarService,
    private currentUserService: CurrentUserService,) {
    // The true paramter tells Angular to rethrow exceptions, so operations like 'bootstrap' will result in an error
    // when an error happens. If we do not rethrow, bootstrap will always succeed.
    super();
  }

  handleError(error: Error | HttpErrorResponse | string) {
    // grab the url where error occured
    const url = this.injector.get(Router).url; // url provided by the router
    const path = this.location.path(); // url provided by the location

    // Log the error (Instance of Error or a string is expected) & notify
    const now = new Date();
    const currUser : ICurrentUser =  this.currentUserService.retrieve();
    if (currUser) {
      this.logger.setCustomHttpHeaders(new HttpHeaders({Authorization: `Bearer ${currUser.accessToken}`}))
    }
    if (error instanceof Error) {
      const stack = error.stack.split('\n').slice(1, 5); // Just take top entries from the stack
      let outStr = 'GlobalErrorHandler Error: ' + error.message + '\n' + 'URL: ' + url + '\n' + 'Stack: ';
      for (const entry of stack) {
        outStr = outStr + entry.trim() + '\n';
      }
      // Log
      // this.logger.error(outStr);
      // Simple alert
      // alert('[1]' + error.message + '\n' + 'Check the console for detailed information');
      // Notify
      this.notifier.notifyError('GlobalErrorHandler Error: ' + error.message + ' error occured. Check your console for details...');
    } else if (error instanceof HttpErrorResponse) {
      // Nothing required here; already have http errors interceptor...
      // const outStr = 'GlobalErrorHandler Error: ' + error.status + ' ' + error.statusText + '\n' + 'URL: ' + url;
      // // Log
      // this.logger.error(outStr);
      // // For now, use simple alert
      // alert('[2]' + error.message + '\n' + 'Check the console for detailed information');
    } else { 
      const outStr = 'GlobalErrorHandler Error: ' + '\n' + 'URL: ' + url;
      // Log
      // this.logger.error(outStr);
      console.log(error);
      // Simple alert
      // alert('[3]' + error + '\n' + 'Check the console for detailed information');
      // Notify  + JSON.stringify(outStr)
      this.notifier.notifyError('GlobalErrorHandler Error: error occured. Check your console for details...');
    }

    // Rethrow the error otherwise it gets swallowed
    // DO NOT DO THAT... Issues
    throw error;
  }
}
