export interface IAlert {
    DeviceId: string,
    CreatedOn: Date,
    CreatedBy?: string,
    ClosedTime: Date,
    SensorName?: string,
    ZoneCode?: string
    Reason: string;
}

export class Alert implements IAlert {
    DeviceId: string;
    CreatedOn: Date;
    CreatedBy?: string;
    ClosedTime: Date;
    SensorName?: string;
    ZoneCode?: string;
    Reason: string;
    constructor(jsonObj: IAlert) {
        this.jsonToClassAdapter(jsonObj);
    }

    private jsonToClassAdapter(jsonObj: any) {
        for (const prop in jsonObj) {
            if (['CreatedOn', 'ModifiedOn', 'ClosedTime'].includes(prop)) {
                this[prop] = jsonObj[prop] ? new Date(jsonObj[prop]) : null;
            } else {
                this[prop] = jsonObj[prop];
            }
        }
    }

    public isClosed() {
        let nullDate = new Date(0);
        return this.ClosedTime !== null && this.ClosedTime !== nullDate;
    }
}

//#region Alert - Interface Type Guard
export function IAlert(inputObj: any): inputObj is IAlert {
    return 'DeviceId' in inputObj && typeof inputObj['DeviceId'] === 'string' &&
        'CreatedOn' in inputObj &&
        'ClosedTime' in inputObj
}

export function isAlert(value: any): value is IAlert[] {
    for (const entry of value) {
        if (!isAlert(entry)) { return false; }
    }
    return true;
}