import { Injectable } from '@angular/core';
import { IAccountBasedData, StartupData } from '@app/models/startup-data';
import { GenericHttpClientService } from 'core-services';
import { Observable } from 'rxjs';
import { API } from '@app/core/api';

const ConfigApiUrl = API.homeApiUrl;

@Injectable({
    providedIn: 'root',
})
export class ConfigService extends GenericHttpClientService<any, string> {
    /**
     * read the initial json (StartupData) from specified url
     * @param accountId the account id
     * @returns Observable of StartupData
     */
    readOne(accountId?: string): Observable<StartupData> {
        return super.readOne(accountId ? `${ConfigApiUrl.one}/${accountId}` : ConfigApiUrl.one);
    }

    /**
     * read the account based data json from specified url
     * @param accountId the account id
     * @returns Observable of AccountBasedData
     */
    accountBasedDataReadOne(accountId: string): Observable<IAccountBasedData> {
        return super.readOne(`${ConfigApiUrl.accountOne}/${accountId}`);
    }
}
