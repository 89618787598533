import { Preference } from './preferences';
import { Permission } from './permissions';

export interface ISubscriberContact {
    SubscriberId?: string,
    Priority?: number,
    ContactGroup?: string,
    Relationship: string,
    FirstName: string,
    LastName: string,
    Email: string,
    Phone: string,
    Id?: string,
    Preferences: Preference[],
    Permissions: Permission[],
    IsDirty: boolean,
    ContactInfoId: string,
}

export class SubscriberContact implements ISubscriberContact {
    SubscriberId: string = "";
    Priority: number = 0;
    ContactGroup: string = "";
    Relationship: string = "";
    FirstName: string = "";
    LastName: string = "";
    Email: string = "";
    Phone: string = "";
    Id: string = "";
    Preferences: Preference[] = [];
    Permissions: Permission[] = [];
    IsDirty: boolean  = false;
    ContactInfoId: string = "";   

    constructor(jsonObj: ISubscriberContact) {
        this.jsonToClassAdapter(jsonObj);
    }

    private jsonToClassAdapter(jsonObj: any) {
        for (const prop in jsonObj) {
            this[prop] = jsonObj[prop];
        }
    }

    public getFullNameWithRelationship(): string {
        return `${this.Relationship} ${this.FirstName} ${this.LastName}`;
    }
}

//#region SubscriberContact - Interface Type Guard
export function isSubscriberContact(inputObj: any): inputObj is ISubscriberContact {
    return 'SubscriberId' in inputObj && typeof inputObj['SubscriberId'] === 'string' &&
        'Priority' in inputObj && typeof inputObj['Priority'] === 'number' &&
        'ContactGroup' in inputObj && typeof inputObj['ContactGroup'] === 'string' &&
        'Relationship' in inputObj && typeof inputObj['Relationship'] === 'string' &&
        'FirstName' in inputObj && typeof inputObj['FirstName'] === 'string' &&
        'LastName' in inputObj && typeof inputObj['LastName'] === 'string' &&
        'Address' in inputObj && typeof inputObj['Address'] === 'string' &&
        'Email' in inputObj && typeof inputObj['Email'] === 'string' &&
        'Phone' in inputObj && typeof inputObj['Phone'] === 'string' &&
        'Id' in inputObj && typeof inputObj['Id'] === 'string'
}

export function isSubscriberContactsList(value: any): value is ISubscriberContact[] {
    for (const entry of value) {
        if (!isSubscriberContact(entry)) { return false; }
    }
    return true;
}