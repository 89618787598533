import { Injectable } from "@angular/core";
import { IAccountBasedData, IAppData, IPushSubscription, StartupData } from "@app/models";
import { BehaviorSubject, Observable, Subject, take, takeUntil } from "rxjs";
import { AccountsService } from "../app-services/accounts.service";
import { ConfigService } from "../api/config.service";
import { AuthService } from "@app/auth/auth.service";
import { UserService } from "@app/core/user/user.service";
import { CurrentUserService } from "@app/auth/current-user.service";

@Injectable({
    providedIn: 'root',
})
export class AppDataService {
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private _appData: BehaviorSubject<IAppData | null> = new BehaviorSubject(null);
    private _currentAppData: IAppData = {} as IAppData;
    private _currentAccountId: string = null;

    constructor(
        private _accountsService: AccountsService,
        private _configService: ConfigService,
        private _authService: AuthService,
        private _currentUserService: CurrentUserService
        ) {
            this._currentUserService.subscribe().pipe(take(2)).subscribe(data => {
                if (data) {
                    this._currentAccountId = data.defaultAccountId;
                }
            });
            if (this._authService.isAuthUserLoggedIn()) {
                this._accountsService.getAccountId().pipe(takeUntil(this._unsubscribeAll)).subscribe((accountId) =>
                { //do get account based data if there is startup data or no account change
                    if (!this._currentAppData || (!!this._currentAccountId && !!accountId && (this._currentAccountId !== accountId))) {
                        this._configService.accountBasedDataReadOne(accountId).subscribe(
                            (data: IAccountBasedData) => this.updateAccountBasedData(data))
                    }
                    this._currentAccountId = accountId;
                });
            } 
        }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for app data
     */
    get appData$(): Observable<IAppData> {
        return this._appData.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * adds the startup data to the app data
     * @param data startup data
     */
    setStartupData(startupData: IAppData): void {
        this._currentAppData = startupData;
        this._appData.next(startupData);
    }

    /**
     * updates the account based in the app data
     * @param accountData account based data
     */
    updateAccountBasedData(accountData: IAccountBasedData): void {
        this._currentAppData.AccountBasedData = accountData;        
        this._appData.next(this._currentAppData);
    }
    /**
     * updates the push notifications in the app data
     * @param notifData notifications data
     */
    updateNotificationsData(notifData: IPushSubscription[]): void {
        this._currentAppData.UserData.NotificationSubscriptions = notifData;
        this._appData.next(this._currentAppData);
    }
}
