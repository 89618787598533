import { Injectable } from '@angular/core';
import { CurrentUserService } from '@app/auth/current-user.service';
import { Subscriber } from '@app/models';
import { BehaviorSubject, take } from 'rxjs';
//TODO-Wassim: pretiffy with Alt+Shift+F. Add description of this class
/**
 * @class descriptino for this class
 */
@Injectable({
    providedIn: 'root',
})
export class AccountsService {
    private accountId = new BehaviorSubject(null);
    private subscribers = new BehaviorSubject([]);

    currentAccountId = this.accountId.asObservable();
    accountSubscribers = this.subscribers.asObservable();

    constructor(
        private _currentUserService: CurrentUserService,
        ) {
            this._currentUserService.subscribe().pipe(take(2)).subscribe(data => {
                if (data) {
                    this.updateAccountId(data.defaultAccountId);
                }
            });
        }

    getAccountId = () => this.currentAccountId;

    updateAccountId = (id: string) => this.accountId.next(id);

    getSubscribers = () => this.accountSubscribers;

    updateSubscribers = (subscriberlist: Subscriber[]) => this.subscribers.next(subscriberlist);
}
