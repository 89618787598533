import { Contact } from './../core/layout/common/quick-chat/quick-chat.types';
import { SubscriberContact,ISubscriberContact } from "./subscriber-contact";
import { Device, IDevice } from "./device";
import { IAccount } from "./account";
import { Feature, IFeature } from "./feature";
import { IPreference, Preference } from "./preferences";
import { AlertTrigger, IAlertTrigger } from "./alert-trigger";
import { BehaviorPattern, IBehaviorPattern } from "./behavior-pattern";
import { IPackage, Package } from './package2'

export interface ISubscriber {
    Id: string,
    SubscriberId: string,
    FirstName: string,
    LastName: string,
    Address?: IAddress,
    Email: string,
    Phone: string,
    SecondPhone?: string,
    AccountId: string,
    Devices?: Device[],
    SubscriberContacts?: SubscriberContact[],
    CreatedOn?: string,
    Account?: IAccount,
    IsActive: boolean,
    Preferences?: Preference[],
    AlertTriggers?:IAlertTrigger[],
    BehaviorPatterns?:IBehaviorPattern[],
    IsDirty: boolean,
    Features: Feature[]
    Package? :IPackage,
    WearableTagName?:string,
    ContactInfoId: string,
}

interface IAddress {
    Id: string,
    City: string,
    Street: string,
    House: string,
    Apartment: string,
    IsDirty: boolean,
}

export class Subscriber implements ISubscriber {
    Id: string = '';
    SubscriberId: string = '';
    FirstName: string = '';
    LastName: string = '';
    Email: string = '';
    Phone: string = '';
    SecondPhone: string = '';
    AccountId: string = '';
    Address: IAddress = { Id:'', City: '', Street: '', House: '', Apartment: '', IsDirty: false };
    Package?: IPackage = { Id:'', SubscriberId:'', PackageId:"", StartDate:'', EndDate:''};
    Devices: Device[] = [];
    SubscriberContacts: SubscriberContact[] = [];
    CreatedOn?: string = '';
    Account?: IAccount = {Name: "", Email: "", Phone:""}
    IsActive: boolean = false;
    IsDirty: boolean = false;
    Features: Feature[] = [];
    Preferences: Preference[] = [];
    AlertTriggers: AlertTrigger[] = [];
    BehaviorPatterns: BehaviorPattern[]=[];
    WearableTagName = "";
    ContactInfoId = "";
    
    constructor(jsonObj?: ISubscriber) {
       !!jsonObj && this.jsonToClassAdapter(jsonObj);
    }

    private jsonToClassAdapter(jsonObj: any) {
        Object.keys(this).forEach(prop => {
            this[prop] = jsonObj[prop];
            if (prop === "Devices" && jsonObj["Devices"]) {
                let devicesArray = (jsonObj[prop] as IDevice[]).map(adaptDevice);
                this[prop] = devicesArray;
            }
            if (prop === "Address" && jsonObj["Address"]) {
                let addressJson = (jsonObj[prop] as IAddress);
                this.Address = addressJson;
            }
            if (prop === "Account" && jsonObj["Account"]) {
                let accountJson = (jsonObj[prop] as IAccount);
                this.Account = accountJson;         
            }
            if (prop === "Preferences" && jsonObj["Preferences"]) {
                let preferencesArray = (jsonObj[prop] as Preference[]).map(adaptPreferences);
                this[prop] = preferencesArray;       
            }
            if (prop === "Features" && jsonObj["Features"]) {
                let featuresArray = (jsonObj[prop] as IFeature[]).map(adaptFeature);
                this[prop] = featuresArray;
            }
            if (prop === "AlertTriggers" && jsonObj["AlertTriggers"]) {
                let alertTriggersArray = (jsonObj[prop] as IAlertTrigger[]).map(adaptAlertTrigger);
                this[prop] = alertTriggersArray;
            }
            if (prop === "BehaviorPatterns" && jsonObj["BehaviorPatterns"]) {
                let behaviorPatternArray = (jsonObj[prop] as IBehaviorPattern[]).map(adaptBehaviorPattern);
                this[prop] = behaviorPatternArray;
            }
            if (prop === "SubscriberContacts" && jsonObj["SubscriberContacts"]) {
                let subscriberContacts = (jsonObj[prop] as ISubscriberContact[]).map(adaptSubscriberContact);
                this[prop] = subscriberContacts;
            }
        })
    }

    public getFullName(): string {
        return `${this.FirstName} ${this.LastName}`;
    }

    public getFullAddressString() {
        let apartment = [null, ''].includes(this.Address.Apartment) ? '' : `/${this.Address.Apartment}`;
        return `${this.Address.Street} ${this.Address.House}${apartment}, ${this.Address.City}`;
    }

    public getPhoneNumbers() {
        let secondPhone = [null, ''].includes(this.SecondPhone) ? '' : `,  ${this.SecondPhone}`;
        return `${this.Phone}${secondPhone}`;
    }

    public getDeviceSerialNumber(): string {
        if (this.Devices && this.Devices.length > 0) {
            return this.Devices[0].SerialNumber;
        }
        return null;
    }

    public getSenseDeviceSerialNumber(): string {
        if (this.Devices && this.Devices.length > 0) {
            return this.Devices.find(d => !d.IsExternalDevice).SerialNumber;
        }
        return null;
    }

    public getDeviceSerialNumbers(): string[] {
        if (this.Devices && this.Devices.length > 0) {
            return this.Devices.map(d => d.SerialNumber);
        }
        return null;
    }
}

function adaptDevice(device: IDevice) {
    return new Device(device);
}

function adaptFeature(feature: IFeature) {
    return new Feature(feature);
}

function adaptAlertTrigger(alertTrigger: IAlertTrigger) {
    return new AlertTrigger(alertTrigger);
}

function adaptBehaviorPattern(behaviorPattern: IBehaviorPattern) {
    return new BehaviorPattern(behaviorPattern);
}
function adaptSubscriberContact(subscriberContact: ISubscriberContact) {
    return new SubscriberContact(subscriberContact);
}
function adaptPreferences(preference: IPreference) {
    return new Preference(preference);
}

//#region Subscriber - Interface Type Guard
export function isSubscriber(inputObj: any): inputObj is ISubscriber {
    return 'Id' in inputObj && typeof inputObj['Id'] === 'string' &&
        'FirstName' in inputObj && typeof inputObj['FirstName'] === 'string' &&
        'LastName' in inputObj && typeof inputObj['LastName'] === 'string' &&
        'Address' in inputObj && typeof inputObj['Address'] === 'string' &&
        'Email' in inputObj && typeof inputObj['Email'] === 'string' &&
        'Phone' in inputObj && typeof inputObj['Phone'] === 'string'
        ;
}

export function isSubscribersList(value: any): value is ISubscriber[] {
    for (const entry of value) {
        if (!isSubscriber(entry)) { return false; }
    }
    return true;
}
//#endregion