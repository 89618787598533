import { NotificationBarComponent } from './notification-bar.component';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { INotificationBarData, NotificationBarType, NotificationBarDefaultValues } from './notification-bar.model';

const _topic = 'notification-bar';

@Injectable({
  providedIn: 'root'
})
export class NotificationBarService {

  _topics = new Map<any, BehaviorSubject<any>>();

  // Publish fully populated Notification object
  notify(barData: INotificationBarData) {
    this.pub(barData);
  }

  // Publish Default Notification
  notifyDefault(msg: string, act: string = '', min: number = NotificationBarDefaultValues.infoDuration) {
    this.pub({ type: NotificationBarType.Default, message: msg, action: act, styleClass: 'default-color-scheme', duration: min });
  }

  // Publish Info Notification
  notifyInfo(msg: string, act: string = '', min: number = NotificationBarDefaultValues.infoDuration) {
    this.pub({ type: NotificationBarType.Info, message: msg, action: act, styleClass: 'info-color-scheme', duration: min });
  }

  // Publish Success Notification
  notifySuccess(msg: string, act: string = '', min: number = NotificationBarDefaultValues.infoDuration) {
    this.pub({ type: NotificationBarType.Success, message: msg, action: act, styleClass: 'success-color-scheme', duration: min });
  }

  // Publish Warning Notification
  notifyWarning(msg: string, act: string = 'Got It', min: number = NotificationBarDefaultValues.actionDuration) {
    this.pub({ type: NotificationBarType.Warning, message: msg, action: act, styleClass: 'warning-color-scheme', duration: min });
  }

  // Publish Error Notification
  notifyError(msg: string, act: string = 'Dismiss', min: number = NotificationBarDefaultValues.actionDuration) {
    this.pub({ type: NotificationBarType.Error, message: msg, action: act, styleClass: 'error-color-scheme', duration: min });
  }

  // Publish data (INotificationBarData interface expected)
  private pub(value: INotificationBarData) {
    if (this._topics.has(_topic)) {
      this._topics.get(_topic)!.next(value); // As of TS 3.7, the '!' "shuts-down" TS2532
    } else {
      this._topics.set(_topic, new BehaviorSubject(value));
    }
  }

  // NotificationBar will subscribe to this, to get the latest notification
  public subscribe() {
    if (this._topics.has(_topic)) {
      return this._topics.get(_topic)!.asObservable();
    } else {
      this._topics.set(_topic, new BehaviorSubject(undefined)); // Subscribing to a topic never published yet results in creation of a new topic as undefined
      return this._topics.get(_topic)!.asObservable();
    }
  }

}
