import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import {
    FuseNavigationService,
    FuseVerticalNavigationComponent,
} from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { User } from 'app/core/user/user.types';
import { UserService } from 'app/core/user/user.service';
import { ICurrentUser } from '@app/auth/auth.model';
import { AccountsService } from '@app/services/app-services/accounts.service';
import { AuthService } from '@app/auth/auth.service';
import { UserProfile } from '@app/models/user-profile';
import * as UserConstants from '@app/constants/user-constants';
import { FuseConfirmationConfig, FuseConfirmationService } from '@fuse/services/confirmation';
import { PermissionsService } from '@app/services/app-services/permissions.service';
import { AppDataService } from '@app/services/store/app-data.service';
import { IAppData } from '@app/models';
import { CurrentUserService } from '@app/auth/current-user.service';

@Component({
    selector: 'classy-layout',
    templateUrl: './classy.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ClassyLayoutComponent implements OnInit, OnDestroy {
    isScreenSmall: boolean;
    navigation: Navigation;
    user: User;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    @Input() isRTL: boolean = false;
    currentUser: ICurrentUser = undefined;
    currentAccountId: string;
    profiles: UserProfile[] = [];
    accountName: string = '';
    userAccounts: User[] = [];
    allAccountsId: string = UserConstants.AllAccountsCode;
    isDeveloper: boolean = false;
    private _appData: IAppData;

    /**
     * Constructor
     */
    constructor(
        private _navigationService: NavigationService,
        private _userService: UserService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private _accountsService: AccountsService,
        private _authService: AuthService,
        private _fuseConfirmationService: FuseConfirmationService,
        private _permissionsService: PermissionsService,
        private _appDataService: AppDataService,
        private _currentUserService: CurrentUserService
    ) {
        this._appDataService.appData$.pipe(takeUntil(this._unsubscribeAll)).subscribe(data => {
            this._appData = data
            this.profiles = this._appData.UserData.UserProfiles;
            if (!this.currentAccountId) {
                this.currentAccountId = this._currentUserService.retrieve()?.defaultAccountId;
                this.accountName = data.UserData.UserProfiles.find(p => p.AccountId === this.currentAccountId).AccountName;
            }
        
        });
        this.isDeveloper = this._permissionsService.isDeveloper();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to navigation data
        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) => {
                this.navigation = navigation;
            });

        // Subscribe to the user service
        this._userService.user$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: User) => {
                this.user = user;
            });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });
        
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation =
            this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
                name
            );

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    isLoggedIn(): boolean {
        return this._authService.isAuthUserLoggedIn();
    }

    /**
     * for use with the angular "trackBy" feature, identifies each element uniquely in the associated component and assigns it to the ngFor directive
     * @param index
     * @param el
     * @returns
     */
    trackByMethod(index: number, el: any): number {
        return el.id;
    }

    setAccount(profile) {
        if (this.accountName === profile.AccountName && this.currentAccountId === profile.AccountId)
            return;

        this.accountName = profile.AccountName;
        this.currentAccountId = profile.AccountId;
        this._accountsService.updateAccountId(this.currentAccountId);
        this.currentAccountId === UserConstants.AllAccountsCode &&
            this.onAllAccountsViewClick();
    }

    onAllAccountsViewClick() {
        const config: FuseConfirmationConfig = {
            title      : 'No Alerts Warning',
            message    : 'Please note that Alerts are not available in <span class="font-medium">All Accounts</span> view!',
            icon       : {
                show : true,
                name : 'heroicons_outline:exclamation',
                color: 'warn'
            },
            actions    : {
                confirm: {
                    show : true,
                    label: 'Ok',
                    color: 'warn'
                },
                cancel : {
                    show : false,
                    label: 'Cancel'
                }
            },
            // dismissible: true
        }

        // Open the dialog and save the reference of it
        const dialogRef = this._fuseConfirmationService.open(config);

        // Subscribe to afterClosed from the dialog reference
        dialogRef.afterClosed().subscribe((result) => {
            console.log(result);
        });
    }
}
