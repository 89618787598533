/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { HeadersInterceptor } from './headers.interceptor';
import { HttpErrorsInterceptor } from './http.errors.interceptor';
import { JwtInterceptor } from './jwt.interceptor';
import { NoopInterceptor } from './noop.interceptor';
import { EnsureHttpsInterceptor } from './ensure-https.interceptor';
import { HttpLoggingInterceptor } from './logging.interceptor';

/** Http interceptors providers in outside-in order */
export const httpInterceptorsProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: HeadersInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },

  // { provide: HTTP_INTERCEPTORS, useClass: EnsureHttpsInterceptor, multi: true }, // Enable this for forcing https
  // { provide: HTTP_INTERCEPTORS, useClass: HttpLoggingInterceptor, multi: true }, // Enable this for intercepting Req/Resp debug

  // Errors interceptor should be the last one
  { provide: HTTP_INTERCEPTORS, useClass: HttpErrorsInterceptor, multi: true },
];
