import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ENV_NAME } from './config-injectable-tokens';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private config: object;
  private settingsFile: string;
  private found = false;
  private foundValue = undefined;

  constructor(@Inject(ENV_NAME) envName: string, private http: HttpClient) {
    this.settingsFile = '/data/app.settings.' + envName + '.json';
  }

  // This is used by the APP_INITIALIZER to obtain initial settings from appSettings.json file
  // Therefore Promise ensures completion prior to application startup
  loadAppSettings(): Promise<any> {
    const promise = this.http.get(this.settingsFile)
          .toPromise()
          .then(settings => {
          this.config = settings;
          return this.config;
          });
      return promise;
      }

  // Returns configuration value based on given path (nested)
  get(...path: any[]) {
    this.found = false;
    this.foundValue = undefined;
    (async () => { await this.getDeep(this.config, path, 0); })();
    return this.foundValue;
  }

  getDeep(obj: any, keys: any[], level: number) {
    this.found = false;
    // console.log('  Searching Level: ' + level + '   for Key: ' + keys[level]);
    if (obj !== null && obj !== undefined) {
      for (const k of Object.keys(obj)) {
        if (this.found) {
          break;
        }
        // console.log('    Reading key: ' + k + ', value: ' + obj[k]);
        if (k === keys[level]) {
          if (obj[k] instanceof Object) {
            this.getDeep(obj[k], keys, level + 1);
          } else {
            this.found = true;
            this.foundValue = obj[k];
            // console.log('      Found key: ' + k + ', value: ' + obj[k]);
            return obj[k];
          }
        }
      }
    }
  }
}
