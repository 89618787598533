export interface IBehaviorPattern {
    Id?: string,
    SubscriberId: string,
    BehaviorPatternCode: string,
    DayCode: string,
    Value: string,
    ValueType: string,
    Order: string,
    IsDirty: boolean
}

export class BehaviorPattern implements IBehaviorPattern {
    Id?: string = "";
    SubscriberId: string = "";
    BehaviorPatternCode: string = "";
    DayCode: string = "";
    Value: string = "";
    ValueType: string = "";
    Order: string = "";
    IsDirty: boolean = false;

    constructor(jsonObject: IBehaviorPattern) {
        this.jsonToClassAdapter(jsonObject);
    }

    private jsonToClassAdapter = (jsonObject: IBehaviorPattern) => Object.keys(this).forEach(prop => this[prop] = jsonObject[prop])
}