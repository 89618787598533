import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { ICurrentUser } from '@app/auth/auth.model';
import * as UserConstants from '@app/constants/user-constants';
import { AccountsService } from '@app/services/app-services/accounts.service';
import { NotificationBarService } from 'notification-bar';
import { Subject, takeUntil } from 'rxjs';
import * as utils from '@app/helpers/general-helper';
import { CurrentUserService } from '@app/auth/current-user.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuardService implements OnDestroy {
    private _currentAccountId: string;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    router: Router;
    notifier: NotificationBarService;

    constructor(
        private _accountsService: AccountsService,
        private _router: Router,
        private _notifier: NotificationBarService,
        private _currentUserService: CurrentUserService,
    ) {
        this._accountsService
            .getAccountId()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((id) => (this._currentAccountId = id));
        this.router = this._router;
        this.notifier = this._notifier;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Gets the profile name of the logged on user in the selected account
     * @returns profile name string
     */
    private _getLoggedUserProfileCode(): string {
        const currentUser = this._currentUserService.retrieve();    
        if (!!currentUser) {
            return currentUser.defaultProfileCode;
        } else return null;
    }

    /**
     * Checks if the logged in user's profile is Developer
     * @returns true if Developer, false if not
     */
    private _isDeveloper(): boolean {
        const developerProfile = this._getLoggedUserProfileCode() === UserConstants.roles.Developer.code;
        const allAccountsSelected = this._currentAccountId === UserConstants.AllAccountsCode;
        return developerProfile || allAccountsSelected;
    }

    /**
     * Checks if the logged in user's profile is Admin in the selected account
     * @returns true if Admin, false if not
     */
    private _isAdmin(): boolean {
        return this._getLoggedUserProfileCode() === UserConstants.roles.Admin.code;
    }

    /**
     * Checks if the logged in user's profile is Developer or Admin in the selected account
     * @returns true if Developer or Admin, false if not
     */
    private _isDeveloperOrAdmin(): boolean {
        return this._isAdmin() || this._isDeveloper();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    canActivate(permissions: string, route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currUser: ICurrentUser = this._currentUserService.retrieve();
        const isLoggedIn = !utils.isNullorUndefined(currUser) && !utils.isNullorUndefined(currUser.accessToken);
        if (isLoggedIn) {
            // logged in so return true if Developer or Admin
            if (permissions === UserConstants.Permissions.Admin) {
                if (this._isDeveloperOrAdmin()) {
                    return true;
                } else{
                    this.router.navigate(['/subscribers-list']);
                }
            } else if (permissions === UserConstants.Permissions.Developer && this._isDeveloper()) {
                return true;
            } else if (permissions === UserConstants.Permissions.AllUsers) {
                return true;
            } else {
                this.router.navigate(['/dashboard']);
            }
        } else {
            // not logged in so redirect to login page with the return url
            this.notifier.notifyWarning('Please Login in order to execute this action.');
            this.router.navigate(['/sign-in']);
            // TODO: Redirect to Login (need to create a service and send a message to app.component to execute logInOut...)
            return false;
        }
    }
}
