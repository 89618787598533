export interface IHandledAlert {
    AlertId: string,
    DeviceId: string,
    ContactId: string,
    State: string,
    CreatedOn: Date,
    ClosedTime: Date,
    CreatedBy: string;
    ModifiedBy: string;    
    ClosedBy: string; 
}

export class HandledAlert implements IHandledAlert {
    AlertId: string;
    DeviceId: string;
    ContactId: string;
    State: string;
    CreatedOn: Date;
    ClosedTime: Date;
    CreatedBy: string;
    ModifiedBy: string;
    ClosedBy: string;  

    constructor(jsonObj: IHandledAlert) {
        this.jsonToClassAdapter(jsonObj);
    }

    private jsonToClassAdapter(jsonObj: any) {
        for (const prop in jsonObj) {
            if (['CreatedOn', 'ModifiedOn', 'ClosedTime'].includes(prop)) {
                this[prop] = jsonObj[prop] ? new Date(jsonObj[prop]) : null;
            } else {
                this[prop] = jsonObj[prop];
            }
        }
    }

    public isClosed(){
        let nullDate = new Date(0);
        return this.ClosedTime != null && this.ClosedTime != nullDate;
    }
}

//#region HandledAlert - Interface Type Guard
export function IHandledAlert(inputObj: any): inputObj is IHandledAlert {
    return 'AlertId' in inputObj && typeof inputObj['AlertId'] === 'string' &&
        'DeviceId' in inputObj && typeof inputObj['DeviceId'] === 'string' &&
        'ContactId' in inputObj && typeof inputObj['ContactId'] === 'string' &&
        'State' in inputObj && typeof inputObj['State'] === 'string' &&
        'CreatedOn' in inputObj &&
        'CreatedBy' in inputObj && typeof inputObj['CreatedBy'] === 'string' &&
        'ModifiedOn' in inputObj &&
        'ModifiedBy' in inputObj && typeof inputObj['ModifiedBy'] === 'string'
}

export function isHandledAlert(value: any): value is IHandledAlert[] {
    for (const entry of value) {
        if (!isHandledAlert(entry)) { return false; }
    }
    return true;
}
