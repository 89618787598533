import { AppSettings } from './app-settings';
const apiUrl = AppSettings.ApiUrl;

export const API = Object.freeze({
    apiUrl: apiUrl,
    homeApiUrl: Object.freeze({
        one: apiUrl + 'home',
        accountOne: apiUrl + 'home/account-based',
    }),
    alertsApiUrl: apiUrl + 'alerts/list-alerts/',
    closingAlertUrl: apiUrl + 'alerts/close-alert',
    closingAlertsUrl: apiUrl + 'alerts/close-all-alerts',
    userApiUrl: Object.freeze({
        one: apiUrl + 'user-management',
        list: apiUrl + 'user-management/list',
        user: apiUrl + 'user-management/user',
        update: apiUrl + 'user-management/update',
    }),
    userAccountApiUrl: Object.freeze({
        one: apiUrl + 'user-management',
        accountlist: apiUrl + 'user-management/accountlist',
    }),
    subscriberContactsUrl: apiUrl + 'subscriber-management/list-subscriber-contacts/',
    subscriberApiUrl: apiUrl + 'subscriber-management',
    deviceApiUrl: apiUrl + 'devices-management',
    alertsUrl: apiUrl + 'alerts/list-device-alerts?',
    alertClassification: apiUrl + 'alerts/alert-classifications',
    handledAlertsUrl: apiUrl + 'alerts/list-alerts-handling-history?',
    handlingAlertUrl: apiUrl + 'alerts/close-alert',
    systemParametersApiUrl: apiUrl + 'system-parameters',
    systemSettingsApiUrl: apiUrl + 'system-parameters/update-settings',
    activitiesApiUrl: apiUrl + 'devices-management/list-sensors-activities?',
    lifeBeaconActivitiesApiUrl: apiUrl + 'devices-management/list-lifeBeacon-activities?',
    route: apiUrl + 'route-management/route?',
    authApiUrl: apiUrl + 'user-management/authenticate',
    lastActivityApiUrl: apiUrl + 'devices-management/list-last-activity',
    pushNotification: apiUrl + 'push-notification/subscription',
    behaviorHistory: apiUrl + 'subscriber-management/calculate-patterns',
    behaviorPatternsUpdate: apiUrl + 'subscriber-management/update-patterns',
    sensorMCUPrximityAddUpdate: apiUrl + 'devices-management/update-mcu-proximity',
    updateDeviceSensors: apiUrl + 'devices-management/update-device-sensors',
    testingApiUrl: Object.freeze({
        simulator: apiUrl + 'testing',
        externalDeviceSimulator: apiUrl + 'alerts/set-alert',
    }),
    loggerApiUrl: apiUrl + 'logs',
});
