export interface ISensorMCUProximity {
    Id: string;
    SensorID: string;
    MCU: string;
    ProximityLevel: number;
}

export class SensorMCUProximity implements ISensorMCUProximity {
    Id: string = null;
    SensorID: string= "";
    MCU: string= "";
    ProximityLevel: number = 0;

    constructor(jsonObj?: ISensorMCUProximity) {
        !!jsonObj && this.jsonToClassAdapter(jsonObj);
    }

    private jsonToClassAdapter(jsonObj: any) {
        for (const prop in this) this[prop] = jsonObj[prop];
    }
}