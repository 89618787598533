import { NgModule } from '@angular/core';
import { MaterialModules } from './material-modules.module';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LayoutModule } from '@angular/cdk/layout';
import { SlideshowModule } from 'image-slideshow';
import { SlideshowComponent } from 'image-slideshow';
import { GoogleChartsModule } from 'angular-google-charts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatGridListResponsiveModule } from './mat-grid-list-responsive/mat-grid-list-responsive.module';
import { SpinnerComponent } from '@app/shared/loading-spinner/loading-spinner.component';
import { SiteDescriptionComponent } from '@app/modules/auth/site-description/site-description.component';
import { FuseCardModule } from '@fuse/components/card/card.module';
import { TranslocoModule } from '@ngneat/transloco';
import { SettingsListComponent } from './settings-list/settings-list.component';

@NgModule({
  entryComponents: [
    SlideshowComponent
  ],  
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslocoModule,
    // vendor
    RouterModule,
    // local
    MaterialModules,
    SlideshowModule,
    MatGridListResponsiveModule,
    GoogleChartsModule.forRoot({ mapsApiKey: 'AIzaSyD-9tSrke72PouQMnMX-a7eZSW0jkFMBWY' }),
    FuseCardModule
  ],
  declarations: [
    SpinnerComponent,
    SiteDescriptionComponent,
    SettingsListComponent
  ],
  exports: [
    // vendor
    FlexLayoutModule,
    LayoutModule,
    RouterModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,

    // local
    MaterialModules,
    SlideshowModule,
    GoogleChartsModule,
    MatGridListResponsiveModule,
    SpinnerComponent,
    SiteDescriptionComponent,
    FuseCardModule,
    TranslocoModule,
    SettingsListComponent
  ]
})
export class SharedModule {}
