const userAgent = window.navigator.userAgent;
const userBrowser = {
  is: {
    chrome:
      userAgent.includes('Chrome') &&
      userAgent.includes('Safari') &&
      !userAgent.includes('Edg'),
    safari:
      !userAgent.includes('Chrome') &&
      userAgent.includes('Safari') &&
      !userAgent.includes('Edg'),
    edge:
      userAgent.includes('Chrome') &&
      userAgent.includes('Safari') &&
      userAgent.includes('Edg'),
    firefox: userAgent.includes('Firefox'),
  },
  name: {
    chrome: 'Chrome',
    edge: 'Edge',
    safari: 'Safari',
    firefox: 'Firefox',
  },
};

const userOS = {
  is: {
    windows: userAgent.includes('Windows'),
    android: userAgent.includes('Android'),
    iOS: userAgent.includes('iPhone'),
    macOS: userAgent.includes('Macintosh'),
  },
  name: {
    windows: 'Windows',
    android: 'Android',
    iOS: 'iOS',
    macOS: 'macOS',
  },
};


export const getBrowserAndOsDetails = () => {
    let browser = '';
    let os = '';
    switch (true) {
      case userBrowser.is.chrome:
        browser = userBrowser.name.chrome;
        break;
      case userBrowser.is.edge:
        browser = userBrowser.name.edge;
        break;
      case userBrowser.is.safari:
        browser = userBrowser.name.safari;
        break;
      case userBrowser.is.firefox:
        browser = userBrowser.name.firefox;
        break;
      default:
        break;
    }
    switch (true) {
      case userOS.is.windows:
        os = userOS.name.windows;
        break;
      case userOS.is.android:
        os = userOS.name.android;
        break;
      case userOS.is.iOS:
        os = userOS.name.iOS;
        break;
      case userOS.is.macOS:
        os = userOS.name.macOS;
        break;
      default:
        break;
    }

    return `${browser} on ${os}`
}
