import { Injectable, OnDestroy } from '@angular/core';
import * as UserConstants from '@app/constants/user-constants';
import { AccountsService } from './accounts.service';
import { Subject, takeUntil } from 'rxjs';
import { AppDataService } from '../store/app-data.service';
import { IAppData } from '@app/models';

@Injectable({
    providedIn: 'root',
})
export class PermissionsService implements OnDestroy {
    _currentAccountId: string;
    private _appData: IAppData;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    constructor(private _appDataService: AppDataService, private _accountsService: AccountsService) {
        this._accountsService
            .getAccountId()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((Id) => (this._currentAccountId = Id));
        this._appDataService.appData$.pipe(takeUntil(this._unsubscribeAll)).subscribe((data) => (this._appData = data));
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    /**
     * Gets the profile name of the logged on user in the selected account
     * @returns profile name string
     */
    private _getLoggedUserProfileName(): string {
        if (this._appData && this._appData.UserData.UserProfiles) {
            const profiles = this._appData.UserData.UserProfiles;
            if (this._isAllAccountsCode(this._currentAccountId)) this._currentAccountId = profiles[0].AccountId;
            const profile = profiles.find((p) => p.AccountId === this._currentAccountId);
            return profile && profile.ProfileName;
        } else {
            return null; //TODO: not valid, need to be an exception
        }
    }

    /**
     * checks if all account id
     * @param accountId account id
     * @returns true if all accounts code
     */
    private _isAllAccountsCode(accountId: string): boolean {
        return accountId === '' || accountId === UserConstants.AllAccountsCode;
    }

    /**
     * checks if the user profile is not Reader return true if not Reader or false if it is Reader
     * @returns true if the logged user is not reader, false if he is reader
     */
    public hasPrivileges(): boolean {
        return this._getLoggedUserProfileName() !== UserConstants.roles.Reader.name;
    }

    /**
     * checks if the user profile is Developer return true if Developer or false if it is not Developer
     * @returns true if the logged user is Developer, false if he is not Developer
     */
    public isDeveloper(): boolean {
        return this._getLoggedUserProfileName() === UserConstants.roles.Developer.name;
    }
}
