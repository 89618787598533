import { getBrowserAndOsDetails } from '../../helpers/user-agents-helper';
import { Injectable } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { IPushSubscription } from '@app/models';
import { PushNotificationService } from '@app/services/api/push-notification.service';
import { NotificationBarService } from 'notification-bar';
import { PushNotificationSubscriptionService } from '../push-notification-subscription.service';

@Injectable({
    providedIn: 'root',
})
export class NotificationMiddlewareService {
    constructor(
        readonly swPush: SwPush,
        private readonly notificationBarService: NotificationBarService,
        private pushService: PushNotificationService,
        private pushNotificationSubscriptionService: PushNotificationSubscriptionService
    ) {}

    userSubscription: IPushSubscription;

    swRegistration: ServiceWorkerRegistration;
    applicationServerPublicKey: string = 'BGRMo6Dr3e1EwBy-hZNWEDIH8ZcKHaQf0ECzIFydl7wr244hzQkGssQiK3z9fWqH2prOcEyl6KPZeQNruGpLE3s';

    public pushNotificationStatus = {
        isSubscribed: false,
        isSupported: false,
        isInProgress: false,
    };

    init() {
        if ('serviceWorker' in navigator && 'PushManager' in window) {
            navigator.serviceWorker
                .getRegistration()
                .then((swReg) => {
                    console.log('Service Worker is registered', swReg);
                    this.swRegistration = swReg;

                    this.checkSubscription();
                })
                .catch(function (error) {
                    console.log('Service Worker Error', error);
                });
        } else {
            console.warn('Push messaging is not supported');
        }
    }

    checkSubscription() {
        this.swRegistration.pushManager.getSubscription().then((subscription) => {
            // console.log("subscription ", subscription);
            this.pushNotificationStatus.isSubscribed = !(subscription === null);
            if (this.pushNotificationStatus.isSubscribed) {
                let sub = pushSubscriptionToLocalSubscription(subscription);
                this.updateSubscriptionOnServer(sub);
                console.log('User is subscribed.');
            } else {
                console.log('User is NOT subscribed.');
            }
        });
    }

    updateSubscriptionOnServer(subscription) {
        this.pushService.create(subscription).subscribe((data: IPushSubscription) => {
            // console.log(data);
            this.userSubscription = data;
            this.notificationBarService.notifySuccess('You have successfully subscribed to notifications service!');
            this.pushNotificationStatus.isSubscribed = true;
            this.pushNotificationSubscriptionService.sendSubscriptionEvent();
        });
    }

    deleteSubscriptionOnServer(subscription: IPushSubscription) {
        this.pushService.deleteSubscription(subscription).subscribe((data) => {
            this.pushNotificationSubscriptionService.sendSubscriptionEvent();
            // console.log(data);
        });
    }

    subscribe() {
        if (this.pushNotificationStatus.isSubscribed) {
            this.notificationBarService.notifyInfo('You already subscribed to notifications service!');
            return;
        }
        this.pushNotificationStatus.isInProgress = true;
        const applicationServerKey = urlB64ToUint8Array(this.applicationServerPublicKey);
        this.swRegistration.pushManager
            .subscribe({
                userVisibleOnly: true,
                applicationServerKey: applicationServerKey,
            })
            .then((subscription) => {
                let sub = pushSubscriptionToLocalSubscription(subscription);
                this.updateSubscriptionOnServer(sub);
                console.log('subscription details: ', sub);
            })
            .catch((err) => {
                console.log('Failed to subscribe the user: ', err);
            })
            .then(() => {
                this.pushNotificationStatus.isInProgress = false;
            });
    }

    unsubscribe() {
        this.pushNotificationStatus.isInProgress = true;
        this.swRegistration.pushManager
            .getSubscription()
            .then((subscription) => {
                if (subscription) {
                    this.userSubscription = pushSubscriptionToLocalSubscription(subscription);
                    this.deleteSubscriptionOnServer(this.userSubscription);
                    return subscription.unsubscribe();
                }
            })
            .catch(function (error) {
                console.log('Error unsubscribing', error);
            })
            .then(() => {
                this.pushNotificationStatus.isSubscribed = false;
                this.pushNotificationStatus.isInProgress = false;
                this.notificationBarService.notifySuccess('You have successfully unsubscribed from notifications service!');
                this.pushNotificationSubscriptionService.sendSubscriptionEvent();
            });
    }
}

function pushSubscriptionToLocalSubscription(subscription: globalThis.PushSubscription) {
    let p256dh = btoa(String.fromCharCode.apply(null, new Uint8Array(subscription.getKey('p256dh'))));
    let auth = btoa(String.fromCharCode.apply(null, new Uint8Array(subscription.getKey('auth'))));
    let sub: IPushSubscription = {
        Id: null,
        UserId: null,
        Endpoint: subscription.endpoint,
        P256DH: p256dh,
        Auth: auth,
        Browser: getBrowserAndOsDetails(),
        IsActive: true,
        DeviceName: '',
    };
    return sub;
}

function urlB64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}
