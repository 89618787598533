import { Injectable } from '@angular/core';
import { SupportedLanguages } from '@app/models/languages';
import { HashMap, TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, filter, pluck } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LanguageService {
    private _rtlLanguages: string[] = ['he', 'ar'];
    private _language = new BehaviorSubject('');
    private _loadedLanguages = new SupportedLanguages();

    constructor(private _translocoService: TranslocoService) {
        //works if the selected language is not loaded
        this._translocoService.events$
            .pipe(
                filter((e) => e.type === 'translationLoadSuccess'),
                pluck('payload')
            )
            .subscribe((lang) => {
                if (!this._isLangLoaded(lang.langName)) {
                    this._changeLanguage(lang.langName);
                    this._setLangLoaded(lang.langName);
                }
            });

        //works if the selected language is loaded
        this._translocoService.langChanges$.subscribe((lang) => {
            if (this._isLangLoaded(lang)) {
                this._changeLanguage(lang);
            }
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * sends the changed language
     * @param lang the language string
     * @returns language behavior subsject
     */
    private _changeLanguage = (lang: string) => this._language.next(lang);

    /**
     * Checks if a given language hass been loaded
     * @param lang language string
     * @returns yes if loaded, no otherwise
     */
    private _isLangLoaded(lang: string): boolean {
        return this._loadedLanguages[lang];
    }

    /**
     *
     * @param lang sets in localstorage the load status of the given language
     */
    private _setLangLoaded(lang: string): void {
        this._loadedLanguages[lang] = true;
        console.log('language load status = ', this._loadedLanguages);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Sets the default language status in the local storage
     * @param lang
     */
    setDefaultLanguageLoadStatus(lang: string): void {
        this._setLangLoaded(lang.toLowerCase());
    }

    /**
     * Checks if language has changed and was loaded
     * @returns the language name
     */
    languageChanged = () => this._language.asObservable();

    /**
     * Checks if the active language is a right-to-left language
     * @returns true if RTL, no if LTR
     */
    isRTL(): boolean {
        return this._rtlLanguages.includes(this._translocoService.getActiveLang());
    }

    /**
     * tranlate using transloco service
     * @param text string
     * @returns translated string
     */
    translate(text: string, params?: HashMap, lang?: string): string {
        return this._translocoService.translate(text, params, lang);
    }
    /**
     * tranlate object using transloco service
     * @param text string array
     * @returns translated string array
     */
    selectTranslateObject(object: any): any {
        return this._translocoService.selectTranslateObject(object);
    }

    /**
     * tranlate as observable using transloco service
     * @param text string 
     * @returns translated string 
     */
    selectTranslate(text: string): any {
        return this._translocoService.selectTranslate(text);
    }

    /**
     * Sets the active UI language
     * @param lang langauge code
     */
    setActiveLang(lang): void {
        this._translocoService.setActiveLang(lang.toLowerCase());
    }

    /**
     * translates text by pieces separated by commas
     * @param text given text
     * @param splitBy character to split by
     * @param separator characted to separate by on return
     * @returns translated text string
     */
    splitAndTranslate(text: string, splitBy: string = ';', separator: string = ' '): string {
        //TODO: language helper/service
        if (!!text) {
            const splitText = text.split(splitBy);
            const indexArray = [];
            splitText.forEach((x, i) => x.includes('*') && indexArray.push(i));
            indexArray.forEach((x) => (splitText[x] = this.splitAndTranslate(splitText[x].toLowerCase(), '*', ' ')));
            return splitText.map((m) => this.translate(m.trim())).join(separator);
        }
        return "";
    }
}
