// Generics-Based Http Client Wrapper service
// To be extended by type-safe objects data services (such as IotEventsService in this example)
// Service provides for compile-time validation and run-time validation against the type

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, OperatorFunction } from 'rxjs';
import { map } from "rxjs/operators";

import { IGenericHttpClientService } from './generic-http-client.interface';

@Injectable({
  providedIn: 'root'
})
export abstract class GenericHttpClientService<T, ID> implements IGenericHttpClientService<T, ID> {

  constructor(private http: HttpClient) { }

  create(uri: string, body: T): Observable<T> {
    return this.http.post<T>(uri, body);
  }

  readOne(uri: string): Observable<T> {
    return this.http.get<T>(uri);
  }

  readList(uri: string): Observable<T[]> {
    return this.http.get<T[]>(uri);
  }

  update(uri: string, body: T): Observable<T> {
    return this.http.put<T>(uri, body);
  }

  delete<T>(uri: string): Observable<any> {
    return this.http.delete<T>(uri);
  }

}


// Generic function (extended per inheriting service) for type-gurding returned results from the server
// The part of generic http error will NOT kick-in, since I have generic http errors interceptors...
// https://ncjamieson.com/how-to-use-type-guards-with-observables/
// export function guard<T, R extends T>(g: (value: T) => value is R, message?: string): OperatorFunction<T, R> {
//   return (source) =>
//     source.pipe(
//       map((value) => {
//         if (g(value)) {
//           console.log(value)
//           return value;
//         }
//         throw new Error(message || "Guard rejection.");
//       })
//     );
// }


// Generic function (extended per inheriting service) for type-gurding returned results from the server
// https://blog.angularindepth.com/rxjs-how-to-use-type-guards-with-observables-11cc4d4f380f
export function guard<T, R extends T>(r: (value: T) => value is R, message?: string): (value: T) => R {
  return value => {
    if (r(value)) {
      // console.log('passed guard ');
      return value;
    }
    // console.log('rejected guard ');
    throw new Error(message || 'Type Guard Rejection');
  };
}
