export interface IPreference  {
    Id: string,    
    SubscriberId: string,    
    SubscriberContactId: string,    
    Name: string,    
    Value: string,    
    UoM: string,    
    Comments: string,
  }

export class Preference implements IPreference {
    Id: string = ""; 
    SubscriberId: string = "";
    SubscriberContactId: string = "";
    Name: string = "";
    Value: string = "";
    UoM: string = "";
    Comments: string = "";
  
    constructor(jsonObject?: IPreference) {
        !!jsonObject && this.jsonToClassAdapter(jsonObject);
    }

    private jsonToClassAdapter = (jsonObject: IPreference) => Object.keys(this).forEach(prop => this[prop] = jsonObject[prop])
}