import { Inject, Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import * as UserConstants from '@app/constants/user-constants';
import { AuthGuardService } from './auth-guard.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(@Inject(AuthGuardService) private _authGuardService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this._authGuardService.canActivate(UserConstants.Permissions.AllUsers, route, state);
    }
}
