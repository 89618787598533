// This includes all tokens we want to Inject into Injectable components/services using InjectionToken

import { InjectionToken } from '@angular/core';

// In the following exmple, I'll be using a token that includes the environment name
// This token will be injected by app.module into config.service, which will use it to load the relevant environment.ENV_NAME.ts
// While I could continue using, inside the service, the environment.ts file, InjectionToken has some advantages:
// 1. Wanted to test the concept ;-)
// 2. The service is not tightly coupled to configuration anymore; I can inject whatever I want when unit-testing...
// 3. Can expand on in later to inject Interfaces that impact behaviors etc of the service/component
export const ENV_NAME = new InjectionToken<string>('environmentName');
export const ENV_FULL_NAME = new InjectionToken<string>('environmentFullName');
