import { Subscriber } from '@app/models';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IsActiveMatchOptions } from '@angular/router';
import { Subject, take, takeUntil } from 'rxjs';
import { FuseVerticalNavigationComponent } from '@fuse/components/navigation/vertical/vertical.component';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseNavigationItem } from '@fuse/components/navigation/navigation.types';
import { FuseUtilsService } from '@fuse/services/utils/utils.service';
import { T } from '@angular/cdk/keycodes';
import { LanguageService } from '@app/services/app-services/language.service';

@Component({
    selector       : 'fuse-vertical-navigation-basic-item',
    templateUrl    : './basic.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FuseVerticalNavigationBasicItemComponent implements OnInit, OnDestroy
{
    @Input() item: FuseNavigationItem;
    @Input() name: string;
    isRTL:boolean = false;
    title: string = "";
    isActiveMatchOptions: IsActiveMatchOptions;
    private _fuseVerticalNavigationComponent: FuseVerticalNavigationComponent;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseUtilsService: FuseUtilsService,
        private _languageService: LanguageService
    )
    {
        // Set the equivalent of {exact: false} as default for active match options.
        // We are not assigning the item.isActiveMatchOptions directly to the
        // [routerLinkActiveOptions] because if it's "undefined" initially, the router
        // will throw an error and stop working.
        this.isActiveMatchOptions = this._fuseUtilsService.subsetMatchOptions;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this._languageService.languageChanged().pipe(
            takeUntil(this._unsubscribeAll)
        ).subscribe(() => {
            this.isRTL = this._languageService.isRTL();
            if (!!this.item) {
                if (this.title == "") {
                    this.title = this.item.title;
                }
                this._languageService.selectTranslate(this.title).pipe(take(1)).subscribe(data => {
                    this.item.title = data
                    this._changeDetectorRef.markForCheck();
                });                
                // Set the "isActiveMatchOptions" either from item's
                // "isActiveMatchOptions" or the equivalent form of
                // item's "exactMatch" option
                this.isActiveMatchOptions =
                    this.item.isActiveMatchOptions ?? this.item.exactMatch
                        ? this._fuseUtilsService.exactMatchOptions
                        : this._fuseUtilsService.subsetMatchOptions;
        
                // Get the parent navigation component
                this._fuseVerticalNavigationComponent = this._fuseNavigationService.getComponent(this.name);
        
                // Mark for check
                this._changeDetectorRef.markForCheck();
        
                // Subscribe to onRefreshed on the navigation component
                this._fuseVerticalNavigationComponent.onRefreshed.pipe(
                    takeUntil(this._unsubscribeAll)
                ).subscribe(() => {
        
                    // Mark for check
                    this._changeDetectorRef.markForCheck();
                });
            }            
        })
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
