import { UserService } from './../core/user/user.service';
import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ICurrentUser } from './auth.model';
import { CurrentUserService } from './current-user.service';
import { NGXLogger } from 'core-services';
import { StorageMap } from 'core-services';
import { ConfigService } from 'core-services';
import { API } from '@app/core/api';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AccountsService } from '@app/services/app-services/accounts.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private currUser: ICurrentUser;
  constructor(private http: HttpClient,
              private configService: ConfigService,
              private logger: NGXLogger,
              private storage: StorageMap,
              private currentUserService: CurrentUserService,
              private _userService: UserService,
              private dialogRef: MatDialog,
              private _accountsService: AccountsService,
              ) {
  }

  // This is called by the APP_INITIALIZER to obtain Last User from localStorage into a memory variable
  // Therefore Promise ensures completion prior to application startup
  // Function also attempts to refresh token 
  loadLastUser(): Promise<any> {
    const promise = this.storage.get<ICurrentUser>('currentUser').toPromise().then((cu: ICurrentUser) => {
      if (this.isNullOrUndefined(cu)) {
        return null;
      } else {
        this.currUser = cu;
        const onstart = new Promise(resolve => {
          // attempt to refresh token on app start up to auto authenticate
          this.refreshToken()
              .subscribe()
              .add(() => resolve);
        }).catch(() => {console.log("catch refresh token")});
  
        // this.currUser = cu;
        // this.refreshToken();
        this.currentUserService.publish(this.currUser);// Publish (to be caught by appComponent; indicate user logged-in)
        return this.currUser;
        }
     });
    return promise;
  }

  public login(userName: string, password: string): Observable<any> {
    const authUrl = API.authApiUrl;

    return this.http.post<any>
      (authUrl, JSON.stringify({userName, password}))
        .pipe (
        map(data =>  this.currUser = this.getCurrentUserObject(data)),                    
        tap(data => {
          if (this.currUser) {
            // Store the user on the user service
            this._userService.user = {
              id    : this.currUser.userId,
              name  : this.currUser.fullName === "null null" ? "" : this.currUser.fullName,
              email : this.currUser.loginName,
              // avatar: 'assets/images/avatars/brian-hughes.jpg',
              status: 'online'
            };
            console.log('Login - Stored User: ', this.currUser); // Debug
            this.startRefreshTokenTimer(); // Start timer for refreshing token in the background
            this.storage.set('currentUser', this.currUser).subscribe(() => {}); // Update Local Storage
            this.currentUserService.publish(this.currUser); // Publish (to be caught by appComponent)
            this._accountsService.updateAccountId(this.currUser.defaultAccountId);
            // return
            return of (this.currUser);
          }
        })
      );
  }

  /**
   * fills the recived data from api in a ICurrentUser object
   * @param data received data from api
   * @returns ICurrentUser
   */
  getCurrentUserObject(data) : ICurrentUser {
    return ({
      userId: data.Id,
      loginName: data.Username,
      fullName: data.FirstName + " " + data.LastName,
      accessToken: data.JwtToken,
      refreshToken: data.RefreshToken,
      defaultAccountId: data.DefaultProfile.AccountId,
      defaultProfileCode: data.DefaultProfile.UserProfile,
      build: data.Build,
      })
  }

  // Delete currentUser entry from memory and localStorage; delete token on the server
  public logout():  Observable<any> {
    // Revoke token from the server DB
    const refreshToken = this.currUser.refreshToken;
    const revokeUrl = API.authApiUrl + '/revoke-token';
    this.dialogRef.closeAll();
    this.http.post<any>(revokeUrl, JSON.stringify({refreshToken})).subscribe();
    // Clear local cache and notify subscribers (to be caught by appComponent / others)
    this.clearStorage(); // Clear Local Storage entry
    this.currUser = null; // Clear memory variable
    this.currentUserService.publish(this.currUser); // Publish state
    this.stopRefreshTokenTimer(); // Stop refreshing token in the background
    this._accountsService.updateAccountId(null);
    // Return
    return of(true);
  }

  getUsername(){
    return this.currUser.loginName;
  }

  /**
   * Gets the server build string
   * @returns string
   */
  getServerBuildString() : string {
    return this.currUser?.build;
  }

  // Executed on startup and on timer
  refreshToken() {
  console.log('Attempting to refresh token...')  
  const refreshToken = this.currUser.refreshToken;
  const refreshUrl = API.authApiUrl + '/refresh-token';

  return this.http.post<any>(refreshUrl, JSON.stringify({refreshToken}))
      .pipe(map((data) => {
        console.log(data);
        this.currUser = this.getCurrentUserObject(data);
          this._userService.user = {
            id    : this.currUser.userId,
            name  : this.currUser.fullName === "null null" ? "" : this.currUser.fullName,
            email : this.currUser.loginName,
            // avatar: 'assets/images/avatars/brian-hughes.jpg',
            status: 'online'
          };
        this.storage.set('currentUser', this.currUser).subscribe(() => {});
        this.currentUserService.publish(this.currUser);// Publish (to be caught by appComponent; indicate user logged-in)
        // console.log('refreshToken - Stored User: ' + JSON.stringify(this.currUser)); // Debug
        this.stopRefreshTokenTimer();
        this.startRefreshTokenTimer();
        return of(true);
      }));
  }

  // verify we have a valid user logged in (in memory or localStorage); refreshToken will be done, if required, when making http request
  public isAuthUserLoggedIn(): boolean {
    if (this.isNullOrUndefined(this.currUser)) {
       return false;
    } else {
        return true;
      } // user already loaded from localStorage during init (if exists)
  }

  private clearStorage(): void {
    this.storage.delete('currentUser').subscribe(() => {});
    localStorage.clear();
    localStorage.setItem('existingUser', 'true');
  }


  // helper methods

  private refreshTokenTimeout;

  private startRefreshTokenTimer() {
      // parse json object from base64 encoded jwt token
      const jwtToken = JSON.parse(atob(this.currUser.accessToken.split('.')[1]));

      // set a timeout to refresh the token a minute before it expires
      const expires = new Date(jwtToken.exp * 1000);
      const timeout = expires.getTime() - Date.now() - (60 * 1000);
      this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);

      // console.log('startRefreshTokenTimer: Timeout=' + timeout + '   Expires=' + expires ); // Debug
  }

  private stopRefreshTokenTimer() {
      clearTimeout(this.refreshTokenTimeout);
  }

  private isNullOrUndefined(obj: any): boolean {
    if (obj === null || obj === undefined) { return true; } else { return false; }
  }
}
